import { CONSTANTS } from "config/constants";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";

import Styles from "./IvsClientPlayer.styles";
const { OT_API_KEY } = CONSTANTS;

const IvsClientPlayer = props => {
  const { id } = useParams();
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const { data: auctionData } = useGetAuctionById(id ?? currentAuction?.id);
  const { stream } = auctionData ?? {};
  const { sessionId, token } = stream ?? {};
  const { numberOfViewers, setNumberOfViewers } = useGlobals();

  const connect = useCallback(() => {
    // eslint-disable-next-line no-undef
    const session = OT.initSession(OT_API_KEY, sessionId);
    session.on("streamCreated", function (event) {
      session.subscribe(event.stream, "subscriber", {
        width: "80%",
        height: "100%"
      });
    });

    session.on({
      connectionCreated: function (event) {
        setNumberOfViewers(numberOfViewers + 1);
      },
      connectionDestroyed: function (event) {
        setNumberOfViewers(numberOfViewers - 1);
      },
      sessionDisconnected: function sessionDisconnectHandler(event) {
        if (event.reason === "networkDisconnected") {
          alert("Your network connection terminated.");
        }
      }
    });

    // Replace token with your own value:
    session.connect(token, function (error) {
      if (error) console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, token]);

  useEffect(() => {
    if (sessionId && token) connect();
  }, [connect, sessionId, token]);

  return <Styles className="IvsClientPlayer" id="subscriber" />;
};

IvsClientPlayer.defaultProps = {};

export default IvsClientPlayer;
