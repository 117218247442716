import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { IconButton, TextField, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { ModalStyles } from "components/Auctioners/createAuction/CreateAuction.styles";
import CustomModal from "components/CustomModal/CustomModal";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { variables } from "config/variables";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useFetchInterviews } from "services/Interviews/interviews.service.hooks";
import { useApproveOrRejectSellerMutation } from "services/Interviews/interviews.service.hooks";
import { Interview } from "services/Interviews/interviews.service.types";
import { fail, successful } from "utils/Swal";

import Styles from "./AdminClientListRequests.styles";
import { AdminClientListRequestsProps as Props } from "./AdminClientListRequests.types";
const { palette } = variables;

const AdminClientListRequests: React.FC<Props> = props => {
  const { data: requestList, isLoading, refetch } = useFetchInterviews();
  const { mutateAsync: approveReject, reset: approveRejectReset } =
    useApproveOrRejectSellerMutation();
  const [openModalCommission, setOpenModalCommission] = useState(false);
  const [commissionValueError, setCommissionValueError] = useState(false);
  const [commissionText, setCommissionText] = useState("");
  const currentInterview = useRef<number | undefined>(undefined);
  const commissionValue = useRef<any>(undefined);

  const handleApproveReject = async (
    interview_id?: number,
    status?: number
  ) => {
    try {
      if (!interview_id || !status)
        throw new Error("Error aprobar o rechazar postulante");
      await approveReject({
        interview_id,
        status,
        commission: commissionValue.current
      });
      successful("Postulante aceptado");
      approveRejectReset();
      refetch();
    } catch (error) {
      fail("Error aprobar o rechazar postulante");
    }
  };

  const isValidCommission = () => {
    if (commissionText.trim() === "") {
      return false;
    }
    return true;
  };

  const handleApproveOrRejectApplication = async () => {
    if (isValidCommission()) {
      await handleApproveReject(currentInterview.current, 3);
      setOpenModalCommission(!openModalCommission);
    } else {
      setCommissionValueError(true);
    }
  };

  const requestCommissionValue = () => {
    return (
      <CustomModal
        isOpen={openModalCommission}
        onClose={() => setOpenModalCommission(!openModalCommission)}
        ariaLabelledby="Modal para solicitar la comisión."
      >
        <ModalStyles className="ModalStyles">
          <Typography variant="h5" component="h5">
            Comisión de venta
          </Typography>
          <TextField
            label="Comisión de venta Ej. 0.4"
            variant="standard"
            ref={commissionValue}
            onChange={e => {
              commissionValue.current = e.currentTarget.value;
              setCommissionText(e.currentTarget.value);
              setCommissionValueError(false);
            }}
            error={commissionValueError}
            helperText={
              commissionValueError
                ? "Debe ingresar un valor de comisión de venta"
                : " "
            }
            className="AdminClientListRequests__textField"
            sx={{ width: "220px", margin: "16px 0" }}
          />
          <Button
            onClick={() => handleApproveOrRejectApplication()}
            variant="contained"
          >
            Confirmar
          </Button>
        </ModalStyles>
      </CustomModal>
    );
  };

  const renderActions = (item: Interview) => {
    const { id, status } = item ?? {};

    return (
      <>
        {status !== 3 && (
          <Tooltip title="Aprobar solicitud del vendedor">
            <IconButton
              onClick={() => {
                currentInterview.current = id;
                setOpenModalCommission(true);
              }}
              sx={{ borderRadius: 500 }}
            >
              <CheckCircleIcon
                fontSize="medium"
                sx={{
                  marginRight: 1,
                  color: palette.chipSuccess
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        {status !== 2 && (
          <Tooltip title="Rechazar solicitud">
            <IconButton onClick={() => handleApproveReject(id, 2)}>
              <DoDisturbIcon
                fontSize="medium"
                sx={{
                  color: palette.chipOrange,
                  marginRight: 1
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <Styles className="AdminClientListRequests">
      {isLoading && <CircularProgress />}
      {requestCommissionValue()}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small" align="left">
                Nombre
              </StyledTableCell>
              <StyledTableCell align="left">Correo</StyledTableCell>
              <StyledTableCell align="left">Teléfono</StyledTableCell>
              <StyledTableCell align="left">Título</StyledTableCell>
              <StyledTableCell align="left">País</StyledTableCell>
              <StyledTableCell align="left">Código de ciudad</StyledTableCell>
              <StyledTableCell align="left">Código postal</StyledTableCell>
              <StyledTableCell align="left">Fecha de solicitud</StyledTableCell>
              <StyledTableCell align="center" size="small" width={16}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestList?.map((item, key) => {
              const { name, surname, email, title } = item ?? {};
              const { country, country_code, postal_code } = item ?? {};
              const { updated_at, phone, status } = item ?? {};

              return (
                <StyledTableRow key={`${`item.item_id`}-${key}`}>
                  <StyledTableCell align="left">
                    {`${name ?? ""} ${surname ?? ""}`.slice(0, 30)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {email?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">{phone ?? ""}</StyledTableCell>
                  <StyledTableCell align="left">
                    {title?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {country?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {country_code ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {postal_code ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dayjs(updated_at).format("DD/MM/YYYY HH:mm a")}
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    <Chip
                      label={
                        status === 1
                          ? "Pendiente"
                          : status === 2
                          ? "Rechazado"
                          : "Aprobado"
                      }
                      variant={status === 3 ? "filled" : "outlined"}
                      color={
                        status === 3
                          ? "success"
                          : status === 2
                          ? "warning"
                          : "info"
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right" size="small">
                    {renderActions(item)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Styles>
  );
};

AdminClientListRequests.defaultProps = {};

export default AdminClientListRequests;
