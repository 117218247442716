import React from "react";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";

import AuctionDetails from "components/Auctioners/auctionDetails/AuctionDetails";
import AuctionInfo from "components/Auctioners/auctionInfo/AuctionInfo";
import ItemCard from "components/Auctioners/itemCard/ItemCard";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import Layout from "components/Layout/Layout";
import { useAppSelector } from "hooks";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import { Item } from "services/Auctioners/Auctioners.service.types";
import { useFetchAuctionAccessCode } from "services/auctionAccess/auctionAccess.service.hooks";
import Styles from "./Auction.styles";

const Auction = () => {
  const { id } = useParams<Record<string, string | undefined>>();
  const numericId = id ? parseInt(id, 10) : undefined;

  const { data: auctionData, isLoading } = useGetAuctionById(numericId);
  const { data: realAccessCode } = useFetchAuctionAccessCode(numericId);
  const { user } = useAppSelector(state => state.authReducer);

  const { prices, items, user_id, access } = auctionData ?? {};
  const isOwner = user_id === user?.id;

  // const renderImageContainer = () => (
  //   <div className="Auction__images-container">
  //     <img
  //       className="Auction__image Auction__image--main"
  //       src={url_logo_1 ? url_logo_1 : IMAGE_PLACEHOLDER}
  //       alt="Logo1"
  //     />
  //     {/* <img
  //       className="Auction__image Auction__image--main"
  //       src={url_logo_2 ? url_logo_2 : IMAGE_PLACEHOLDER}
  //       alt="Logo2"
  //     />
  //     <img
  //       className="Auction__image Auction__image--main"
  //       src={url_logo_3 ? url_logo_3 : IMAGE_PLACEHOLDER}
  //       alt="Logo3"
  //     /> */}
  //   </div>
  // );

  const renderItemContainer = () => (
    <div className="Auction__items-container">
      {items?.map((item: Item, idx: number) => (
        <ItemCard item={item} auctionId={id ?? ""} key={idx} />
      ))}
    </div>
  );

  const renderDivider = <hr className="Auction__divider" />;

  return (
    <Layout>
      <Styles className="Auction">
        {isLoading && <CircularProgress />}
        {realAccessCode && isOwner && access === 4 ? (
          <Alert severity="info">{`Código de acceso a la subasta: ${realAccessCode}`}</Alert>
        ) : null}
        <div className="Auction__container">
          {/* TODO: Responsive */}
          {/* {renderImageContainer()} */}
          {renderDivider}
          <AuctionInfo auctionData={auctionData} />
          {renderDivider}
          {renderItemContainer()}
          {renderDivider}
          <div className="Auction__items-container">
            <AuctionDetails auctionData={auctionData} prices={prices} />
          </div>
          {renderDivider}
        </div>
      </Styles>
    </Layout>
  );
};
export default Auction;
