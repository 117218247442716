import React, { ReactNode } from "react";
import { CircularProgress } from "@mui/material";
// import history from "../../routes/history";
import { useNavigate } from "react-router-dom";

type HomeSectionProps = {
  title: string;
  buttonText: string;
  subtitle?: string;
  className?: string;
  isLoading: boolean;
  isHidden: boolean;
  buttonRoute?: string;
  children: ReactNode;
};

const HomeSection = ({
  title,
  buttonText,
  subtitle,
  className,
  isLoading,
  isHidden,
  buttonRoute,
  children
}: HomeSectionProps) => {
  const navigate = useNavigate();

  if (!isLoading && isHidden) return <></>;

  const handleLinkClick = () => {
    navigate(buttonRoute ?? "/");
  };

  return (
    <div className={`py-6 ${className}`}>
      {!isLoading ? (
        <>
          <div className="flex items-center gap-x-5">
            <span className="text-new-100 text-2xl font-Poppins-Sb">
              {title}
            </span>
            {buttonRoute && buttonText ? (
              <button
                className="text-[19px] text-new-200 font-Poppins-Md"
                onClick={handleLinkClick}
              >
                {buttonText}
              </button>
            ) : null}
          </div>
          <div className="font-Poppins-Rg text-base text-new-100 pt-2">
            {subtitle}
          </div>
          <div className="py-6">{children}</div>
        </>
      ) : (
        <div className="flex justify-center min-h-[300px] items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default HomeSection;
