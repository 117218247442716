import React, { Fragment } from "react";
import { FieldValues, useForm } from "react-hook-form";
// import history from "routes/history";
import { useNavigate } from "react-router-dom";

import { SellerData } from "services/Auth/Auth.services.types";

import { registerSeller } from "../../services/Auth/Auth.service";
import { fail, successful } from "../../utils/Swal";

const RegisterSeller = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const FormLogin = async (values: FieldValues) => {
    const data: SellerData = {
      ...values,
      auction_house: values.auction_house ? 1 : 0,
      individual: values.individual ? 1 : 0,
      gallery_dealer: values.gallery_dealer ? 1 : 0
    };
    await registerSeller(data)
      .then(response => {
        successful("Solicitud enviada con exito");
        navigate("/");
      })
      .catch(error => {
        console.error(error);
        fail("Hubo un error con los campos de la solicitud");
      });
  };

  return (
    <Fragment>
      <div className="bg-[url('/src/assets/images/background-formulario.jpg')] h-screen lg:h-screen bg-cover bg-center opacity-100 bg-scroll">
        <div className="px-96">
          <div className="text-white pt-10 text-center px-32">
            <h3 className="lg:text-6xl font-bold text-primary">
              Live<span className="text-white">Subastas</span>
            </h3>
            <div className="mt-4 tracking-wide">
              <h4 className="font-Poppins-Sb text-2xl text-white">
                Haga Crecer Su Negocio Y Deleite A Sus Consignadores
              </h4>
              <h3 className="mt-4 text-xl text-white">
                Incluya su próxima subasta en LiveSubastas y llegue a millones
                de compradores en todo el mundo.
              </h3>
            </div>
          </div>
          <form onSubmit={handleSubmit(FormLogin)}>
            <div className="mt-6 px-32">
              <div className="p-8 bg-white rounded-3xl">
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="name"
                    >
                      Nombres
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="name"
                      type="text"
                      {...register("name", { required: true })}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="lastname"
                    >
                      Apellidos
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="surname"
                      type="text"
                      {...register("surname", { required: true })}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-4">
                  <div>
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="title"
                    >
                      Título
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="title"
                      type="text"
                      {...register("title", { required: true })}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 mt-4">
                  <div>
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="contry_code"
                    >
                      código ciudad
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="contry_code"
                      type="text"
                      {...register("contry_code", { required: true })}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="country"
                    >
                      País
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="country"
                      type="text"
                      {...register("country", { required: true })}
                    />
                  </div>
                  <div className="grid grid-cols-1 w-100">
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="number"
                    >
                      Número teléfono
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="phone"
                      type="phone"
                      {...register("phone", { required: true })}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 mt-4">
                  <div>
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="email"
                    >
                      correo electrónico
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="email"
                      type="email"
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-default font-Poppins-Md text-sm mb-1 uppercase tracking-wider"
                      htmlFor="postal_code"
                    >
                      código postal / zip
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary focus:ring-2 ring-primary"
                      id="postal_code"
                      type="text"
                      {...register("postal_code", { required: true })}
                    />
                  </div>
                </div>
                <div className="mt-4 font-Poppins-Md text-default uppercase">
                  Soy
                  <div className="grid grid-cols-2 gap-2 mt-1">
                    <div>
                      <label className="block text-gray-500 font-Poppins-Md">
                        <input
                          className="mr-2 leading-tight h-4 w-4 border border-gray-300 rounded-sm transition duration-200"
                          type="checkbox"
                          {...register("auction_house")}
                        />
                        <span className="text-sm ">Casa de subastas</span>
                      </label>
                    </div>
                    <div>
                      <label className="block text-gray-500 font-Poppins-Md">
                        <input
                          className="mr-2 leading-tight h-4 w-4 border border-gray-300 rounded-sm transition duration-200"
                          type="checkbox"
                          {...register("individual")}
                        />
                        <span className="text-sm">Individuo / Consignador</span>
                      </label>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-2 mt-2">
                    <label className="block text-gray-500 font-Poppins-Md">
                      <input
                        className="mr-2 leading-tight h-4 w-4 border border-gray-300 rounded-sm transition duration-200"
                        type="checkbox"
                        {...register("gallery_dealer")}
                      />
                      <span className="text-sm ">Galería / Distribuidor</span>
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-1 mt-6">
                  <button
                    className="w-full bg-primary hover:bg-hprimary text-white font-Poppins-Bd py-3 px-4 rounded"
                    type="submit"
                  >
                    Enviar Solicitud
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default RegisterSeller;
