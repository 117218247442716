import { CircularProgress } from "@mui/material";
import { forwardRef, PropsWithChildren } from "react";
import React from "react";

import { CircularProgressStyled, Styles } from "./Button.styles";
import { ButtonProps as Props } from "./Button.types";

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (props, ref) => {
    const { children, className, disabled } = props;
    const { startIcon, loading, sx } = props;

    return (
      <Styles
        {...props}
        ref={ref}
        className={className}
        disabled={disabled || !!loading}
        startIcon={
          loading ? (
            <CircularProgress sx={CircularProgressStyled} size={16} />
          ) : (
            startIcon
          )
        }
        sx={sx}
      >
        {loading ? "Espere..." : children}
      </Styles>
    );
  }
);

export default Button;
