import { variables } from "config/variables";
import styled from "styled-components";

import { NotificationCardStyledProps as Props } from "./NotificationCard.types";

const { palette, fonts } = variables;

const NotificationCardStyled = styled.div<Props>`
  width: 480px;
  border-bottom: 1px solid ${palette.neutro.second2};
  background-color: ${props =>
    !props.readed ? palette.neutro.second2 : "white"};

  * > h2 {
    font-family: ${fonts["Poppins-Lt"]};
    color: ${palette.blackCharcoal};
  }

  * > p {
    font-family: ${fonts["Poppins-Rg"]};
  }
  .NotificationCard__category {
    ont-size: 12px;
    color: #666;
  }

  .NotificationCard__amount {
    font-weight: bold;
    color: #333;
  }

  .NotificationCard__time {
    font-size: 10px;
    color: #999;
  }

  .NotificationCard {
    &__content {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      font-size: 14px;
    }

    &__avatar {
      border: 1px solid ${palette.neutro.second2};
    }
  }
`;

export default NotificationCardStyled;
