import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { CONSTANTS } from "config/constants";
import { useAppSelector } from "hooks";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import Styles from "./IvsPlayer.styles";

const { OT_API_KEY } = CONSTANTS;

const IvsPlayer = props => {
  const { id } = useParams();
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const { data: auctionData, isLoading } = useGetAuctionById(
    id ?? currentAuction?.id
  );
  const { stream } = auctionData ?? {};
  const { sessionId, token } = stream ?? {};

  const connect = useCallback(() => {
    // eslint-disable-next-line no-undef
    const session = OT.initSession(OT_API_KEY, sessionId);

    // eslint-disable-next-line no-undef
    const publisher = OT.initPublisher(
      "publisher",
      {
        insertMode: "append",
        width: "100%",
        height: "100%"
      },
      error => {
        if (error) {
          alert(error.message);
        }
      }
    );

    // Replace token with your own value:
    session.connect(token, function (error) {
      if (error) {
        //console.log(error);
      } else {
        // document.getElementById('disconnectBtn').style.display = 'block';
        //alert('Connected to the session.');

        if (session.capabilities.publish === 1) {
          session.publish(publisher);
        } else {
          //alert("You cannot publish an audio-video stream.");
        }
      }
    });
  }, [sessionId, token]);

  useEffect(() => {
    if (sessionId && token) connect();
  }, [connect, sessionId, token]);

  return (
    <Styles className="IvsPlayer" id="publisher">
      {isLoading && <CircularProgress />}
    </Styles>
  );
};

IvsPlayer.defaultProps = {};

export default IvsPlayer;
