import React from "react";

import { variables } from "../../../config/variables";
import Styles from "./CustomButton.styles";
import { CustomButtonProps as Props } from "./CustomButton.types";
const { palette } = variables;

function CustomButton(props: Props) {
  const { disabled, color = palette.primary } = props;
  const { icon, className = "", textClassName = "" } = props;
  const { type, text = "", onClick } = props;

  return (
    <Styles
      className={`Button ${className}`}
      onClick={!disabled ? onClick : undefined}
      type={type}
      disabled={disabled}
      color={color}
    >
      <div className="Button__container">
        {icon}
        <p className={`Button__text Button__text__${type} ${textClassName}`}>
          {text}
        </p>
      </div>
    </Styles>
  );
}

export default CustomButton;
