import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { ROL } from "config/roles.config";

const RouteAdmin = () => {
  const { user } = useSelector(state => state.authReducer);

  return user?.rol === ROL.ADMIN ? <Outlet /> : <Navigate to="/admin/login" />;
};

export default RouteAdmin;
