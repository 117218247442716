import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import { variables } from "config/variables";

import { InputBaseStyledProps as Props } from "./InputBase.types";

const { fonts, palette } = variables;

const InputBaseStyled = styled(InputBase)<Props>(({ error }) => ({
  "label + &": {
    marginTop: "24px"
  },
  "& .MuiInputBase-input": {
    borderRadius: 16,
    position: "relative" as const,
    border: `1px solid ${error ? palette.error : palette.blackLight}`,
    fontSize: 16,
    width: "100%",
    padding: "10px 14px",
    transition: "border-color 0.2s ease, box-shadow 0.2s ease",
    fontFamily: fonts["Poppins-El"],
    lineHeight: 1.43,
    wordSpacing: "1.6px",

    "&:focus": {
      borderColor: palette.primary,
      boxShadow: `${palette.primaryBackgroundLight} 0 0 0 0.2rem`
    }
  }
}));

export const IconStyle = {
  position: "absolute" as const,
  right: "16px",
  cursor: "pointer"
};

export default InputBaseStyled;
