import Button from "components/Globals/Button/Button";
import NotificationCard from "components/NotificationCard/NotificationCard";
import EmptyState from "components/emptyState/EmptyState";
import useGlobals from "context/globals/globals.hooks";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { useFetchNotifications } from "services/notifications/notifications.service.hooks";
import { useCreateChat } from "services/userChat/userChat.service.hooks";

import Styles from "./Notifications.styles";
import { NotificationsProps as Props } from "./Notifications.types";
dayjs.extend(relativeTime);

const Notifications: React.FC<Props> = props => {
  const { data: notificationList } = useFetchNotifications(50);
  const { currentNotification } = useGlobals();
  const { mutateAsync, reset } = useCreateChat();

  const { created_at, owner_user_id, title } = currentNotification ?? {};
  const { message, owner_name } = currentNotification ?? {};
  const timeAgo = dayjs(created_at ?? new Date()).from(new Date());

  const handleCreateChat = async (uid: number) => {
    try {
      mutateAsync(uid);
      reset();
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.open(`/panel/chat`);
    } catch (error) {
      fail("Error al crear el chat con el usuario");
    }
  };

  return (
    <Styles className="Notifications">
      <div className="Notifications__short">
        {notificationList?.map((notification, key) => (
          <NotificationCard notification={notification} key={key} />
        ))}
      </div>
      {currentNotification ? (
        <div className="Notifications__details">
          <div className="Notifications__content">
            <h2 className="Notifications__title">{`${
              owner_name ?? "LiveSubastas"
            }: ${title}`}</h2>
            <p>{`${message ?? ""}`}</p>
            <p className="Notifications__time">{`Notificación realizada: Hace ${timeAgo
              ?.replace("ago", "")
              .replace("days", "días")
              .replace("hours", "horas")
              .replace("month", "mes")
              .replace("year", "año")}.`}</p>
            {owner_user_id ? (
              <Button
                onClick={() => handleCreateChat(owner_user_id)}
              >{`Iniciar chat con ${owner_name}`}</Button>
            ) : null}
          </div>
        </div>
      ) : (
        <EmptyState />
      )}
    </Styles>
  );
};

Notifications.defaultProps = {};

export default Notifications;
