import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, InputLabel } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import ErrorComponent from "components/Globals/ErrorComponent/ErrorComponent";
import InputBase from "components/Globals/InputBase/InputBase";
import useAuth from "context/auth/auth.hooks";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { resetAuctionsRedux } from "redux/actions/auctionActions";
// import history from "routes/history";
import { useNavigate } from "react-router-dom";

import { schema } from "./LoginModal.helpers";
import Styles from "./LoginModal.styles";
import { LoginModalProps as Props } from "./LoginModal.types";

const LoginModal: React.FC<Props> = props => {
  const { setModalLogin, modalLogin, onOpenCloseModalRegister } = props;
  const { customTitle, hideRegister, customRoute } = props;
  const { isLoading } = useGlobals();
  const { user: reduxUser } = useAppSelector(state => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { signIn, setAuthError } = useAuth();
  const { setForgotFlow } = useAuth();
  const [errorMessageText, setErrorMessageText] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  const FormLogin = async (values: FieldValues) => {
    setErrorMessageText("");
    dispatch(resetAuctionsRedux());
    const { email, password } = values;
    try {
      await signIn(email, password);
    } catch (error) {
      setAuthError(error);
      if (error?.name === "UserNotConfirmedException")
        onOpenCloseModalRegister(email, password);

      const errorMessage =
        error?.name === "NotAuthorizedException"
          ? "Usuario o contraseña incorrectos"
          : "No se pudo iniciar sesión";
      setErrorMessageText(errorMessage);
      fail("Error al conectarse");
    }
  };

  useEffect(() => {
    if (!modalLogin) {
      reset();
      setAuthError(undefined);
      setErrorMessageText("");
    }
  }, [modalLogin, reset, setAuthError]);

  useEffect(() => {
    if (reduxUser?.id && modalLogin) {
      const route = customRoute ?? "/panel/mi-perfil";
      navigate(route);
      setModalLogin(false);
    }
  }, [customRoute, modalLogin, reduxUser, setModalLogin, navigate]);

  return (
    <>
      {isLoading && <CircularProgress />}
      <CustomModal
        onClose={() => setModalLogin(!modalLogin)}
        isOpen={modalLogin}
        ariaLabelledby="Inicio de sesión de usuario"
      >
        <Styles className="LoginModal">
          <div className="LoginModal__container--components">
            <div className="LoginModal__container--image" />

            <div className="LoginModal__container--form">
              <div className="LoginModal__container--form-header">
                {/* <img
                  src="/images/logo.png"
                  className="LoginModal__container--logo"
                  alt="logo"
                /> */}
                <div>
                  <h1 className="LoginModal__text LoginModal__text--title">
                    Inicia sesión{" "}
                    <span className="LoginModal__text LoginModal__text--subtitle">
                      {` ${customTitle ?? "y empieza a ofertar"}`}
                    </span>
                  </h1>
                </div>
              </div>

              <div>
                {errorMessageText && (
                  <p className="LoginModal__component--error">
                    {errorMessageText}
                  </p>
                )}
                <form
                  onSubmit={handleSubmit(FormLogin)}
                  className="LoginModal__component--form"
                >
                  <FormControl
                    variant="standard"
                    className="LoginModal__component--form-control"
                  >
                    <InputLabel
                      shrink
                      htmlFor="email"
                      className="LoginModal__component--input"
                    >
                      Correo electrónico
                    </InputLabel>
                    <InputBase
                      id="email"
                      placeholder="Ingrese correo electrónico"
                      {...register("email")}
                      autoComplete="email"
                      error={!!errors.email}
                    />
                    <ErrorComponent
                      validation={!!errors.email}
                      content={errors.email?.message}
                    />
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className="LoginModal__component--form-control"
                  >
                    <InputLabel
                      shrink
                      htmlFor="password"
                      className="LoginModal__component--input"
                    >
                      Contraseña
                    </InputLabel>
                    <InputBase
                      id="password"
                      placeholder="Ingrese contraseña"
                      type="password"
                      {...register("password")}
                      error={!!errors.password}
                      autoComplete="current-password"
                    />
                    <ErrorComponent
                      validation={!!errors.password}
                      content={errors.password?.message}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className="LoginModal__component--form-control"
                  >
                    Entrar
                  </Button>
                </form>
              </div>

              {!hideRegister && (
                <div className="LoginModal__textContainer">
                  <p className="LoginModal__text LoginModal__text--register">
                    ¿Aún no tienes una cuenta?.{" "}
                    <span
                      onClick={() => {
                        reset();
                        onOpenCloseModalRegister();
                      }}
                      className="LoginModal__text--register-action text-primary"
                    >
                      Regístrate
                    </span>
                  </p>
                  <p className="LoginModal__text LoginModal__text--register">
                    ¿Olvidaste tu contraseña?.{" "}
                    <span
                      id="GoToLogin"
                      onClick={() => {
                        setForgotFlow(true);
                        onOpenCloseModalRegister();
                      }}
                      className="LoginModal__text--register-action text-primary"
                    >
                      Recupérala aquí
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </Styles>
      </CustomModal>
    </>
  );
};

LoginModal.defaultProps = {};

export default LoginModal;
