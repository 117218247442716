import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { TableHead, TableRow, TextField } from "@mui/material";
import { Table, TableBody, TableCell } from "@mui/material";
import React, { useEffect, useState } from "react";

import { findFirstInvalidRange } from "./AddPrima.helpers";
import { exampleData } from "./AddPrima.helpers";
import Styles from "./AddPrima.styles";
import { Error, AddPrimaProps as Props } from "./AddPrima.types";

const AddPrima: React.FC<Props> = props => {
  const { data, setData, setIsError } = props;
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (data) {
      setError(findFirstInvalidRange(data));
    }
  }, [data]);

  useEffect(() => {
    if (error?.val !== undefined) setIsError(true);
  }, [error, setIsError]);

  const handleFieldChange = (
    id: number,
    field: string,
    value?: number | string
  ) => {
    setData(prevData => {
      return prevData.map(item => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      });
    });
  };

  const formatPrima = (val?: string) => {
    if (val === undefined) return;
    if (val === "NaN") return undefined;
    if (val === "undefined") return undefined;
    const newVal = +val.replace("%", "");
    const calculatedVal = newVal <= 1 ? newVal * 100 : newVal;
    return `${calculatedVal}%`.trim();
  };

  const renderTable = () => {
    return (
      <div className="AddPrima__table w-1/2">
        <h3 className="mt-8 mb-4">{`Comisión del comprador`}</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Rango</h3>
              </TableCell>
              <TableCell>
                <h3>Prima</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, id) => {
              const { prima } = item ?? {};

              return (
                <TableRow key={item.id}>
                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      value={item.rangoInferior}
                      sx={{ width: "100px", mr: 1 }}
                      error={
                        error?.idx === id && error?.val === "rangoInferior"
                      }
                      placeholder={item.id === 0 ? "0" : "Inferior"}
                      type="number"
                      onChange={event => {
                        const val = event.target.value
                          ? +event.target.value
                          : undefined;
                        handleFieldChange(item.id, "rangoInferior", val);
                      }}
                      variant="standard"
                    />
                    <ArrowRightAltIcon />
                    <TextField
                      value={item.rangoSuperior}
                      sx={{ width: "100px", ml: 1 }}
                      error={
                        error?.idx === id && error?.val === "rangoSuperior"
                      }
                      placeholder={"Superior"}
                      type="number"
                      onChange={event => {
                        const val = event.target.value
                          ? +event.target.value
                          : undefined;
                        handleFieldChange(item.id, "rangoSuperior", val);
                      }}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={
                        prima !== undefined && String(prima) !== "NaN"
                          ? prima
                          : undefined
                      }
                      placeholder="Prima"
                      type="text"
                      error={prima && prima > 1 ? true : false}
                      onChange={event => {
                        handleFieldChange(
                          item.id,
                          "prima",
                          formatPrima(`${event.target.value}`)
                        );
                      }}
                      variant="standard"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  const renderTableDisabledSingle = () => {
    return (
      <div className="AddPrima__table">
        <h3 className="mt-8 mb-4 opacity-40">{`Ejemplo prima única`}</h3>
        <Table className="AddPrima__table--disabled">
          <TableHead>
            <TableRow>
              <TableCell>
                <h3 className="opacity-40">Rango</h3>
              </TableCell>
              <TableCell>
                <h3 className="opacity-40">Prima</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  value={0}
                  sx={{ width: "100px", mr: 1 }}
                  type="number"
                  disabled
                  variant="standard"
                />
                <ArrowRightAltIcon color="disabled" />
                <TextField
                  value={null}
                  sx={{ width: "100px", ml: 1 }}
                  placeholder={"Superior"}
                  type="number"
                  disabled
                  variant="standard"
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={"20%"}
                  placeholder="Prima"
                  disabled
                  variant="standard"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  };

  const renderTableDisabled = () => {
    return (
      <div className="AddPrima__table">
        <h3 className="mt-8 mb-4 opacity-40">{`Ejemplo de tabla completa`}</h3>
        <Table className="AddPrima__table--disabled">
          <TableHead>
            <TableRow>
              <TableCell>
                <h3 className="opacity-40">Rango</h3>
              </TableCell>
              <TableCell>
                <h3 className="opacity-40">Prima</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exampleData.map((item, id) => (
              <TableRow key={item.id}>
                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    value={item.rangoInferior}
                    sx={{ width: "100px", mr: 1 }}
                    placeholder={item.id === 0 ? "0" : "Inferior"}
                    type="number"
                    disabled
                    variant="standard"
                  />
                  <ArrowRightAltIcon color="disabled" />
                  <TextField
                    value={item.rangoSuperior}
                    sx={{ width: "100px", ml: 1 }}
                    placeholder={"Superior"}
                    type="number"
                    disabled
                    variant="standard"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={item.prima}
                    placeholder="Prima"
                    disabled
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Styles className="AddPrima">
      <h2 className="AddPrima__title">Prima de comprador</h2>
      <p>
        La comisión del comprador es una tarifa de servicio de la que el
        comprador es responsable, agregada al principio del artículo. La
        comisión se puede establecer desde 0% y hasta un máximo del 3% por
        encima de la comisión del comprador cobrada a los postores en sala. La
        comisión del comprador en LiveSubastas no puede exceder la comisión del
        comprador otorgada a los postores en línea en otro sitio web del
        subastador u otro marketplace.
      </p>
      <div className="AddPrima__tablesContainer">
        {renderTable()}
        <div className="w-1/2 flex flex-col align-start">
          {renderTableDisabledSingle()}
          {renderTableDisabled()}
        </div>
      </div>
    </Styles>
  );
};

AddPrima.defaultProps = {};

export default AddPrima;
