import AddCardIcon from "@mui/icons-material/AddCard";
import DescriptionIcon from "@mui/icons-material/Description";
import ForumIcon from "@mui/icons-material/Forum";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Avatar } from "@mui/material";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import ReviewCard from "components/ReviewCard/ReviewCard";
import EmptyState from "components/emptyState/EmptyState";
import useGlobals from "context/globals/globals.hooks";
import FlatList from "flatlist-react";
import React from "react";
import { SellerReview } from "services/Auctioners/Auctioners.service.types";
import { useFetchClientInfo } from "services/Interviews/interviews.service.hooks";
import { useFetchClientComments } from "services/Interviews/interviews.service.hooks";

import Styles, { iconStyle } from "./ClientInfoDetails.styles";
import { ClientInfoDetailsProps as Props } from "./ClientInfoDetails.types";

const defaultImage = "/images/LiveLogoRounded.png";

const ClientInfoDetails: React.FC<Props> = props => {
  const { selectedClient } = useGlobals();
  const { data, isLoading } = useFetchClientInfo(selectedClient);
  const { data: commentList, isLoading: isLoadingComments } =
    useFetchClientComments(selectedClient);
  const { email, description, id: auctionId } = data ?? {};
  const { auctions, commentsCount, winners, logo, photo } = data ?? {};
  const {
    name,
    surname,
    rol = 1,
    country,
    phone,
    payment_registred
  } = data ?? {};
  const clientImage = photo ?? logo ?? defaultImage;

  const renderRol = (rol: number) => {
    switch (rol) {
      case 1:
        return "Comprador";
      case 5:
        return "Vendedor";
      case 10:
        return "Admin";
      default:
        return "Comprador";
    }
  };

  const renderComments = () => {
    return (
      <section className="ClientInfoDetails__comments" id="reviewsSection">
        <h3 className="ClientInfoDetails__title">Reseñas y comentarios</h3>
        <FlatList
          list={commentList ?? []}
          renderItem={(review: SellerReview) => (
            <ReviewCard
              key={review.id} // Usa un identificador único como clave
              review={review}
              className="ClientInfoDetails__reviewCard"
            />
          )}
          renderWhenEmpty={() => <EmptyState />}
          sortBy={["updated_at"]}
          sortDescending
        />
      </section>
    );
  };

  const renderClientInfo = () => {
    return (
      <div className="ClientInfoDetails__clientInfo">
        <Avatar
          src={clientImage}
          alt="iconPersonSideBar"
          className="ClientInfoDetails__clientInfo__avatar"
          sx={{ width: 56, height: 56, marginBottom: 2 }}
        />
        <h3>{`${name ?? "cliente"} ${surname ?? ""}`}</h3>
        <p>{renderRol(rol)}</p>
        <br />
        <div className="ClientInfoDetails__clientInfo__section">
          <p>Subastas donde participó</p>
          <p>{auctions ?? 0}</p>
        </div>
        <div className="ClientInfoDetails__clientInfo__section">
          <p>Lotes ganados</p>
          <p>{winners ?? 0}</p>
        </div>
        <div className="ClientInfoDetails__clientInfo__section">
          <p>Comentarios realizados</p>
          <p>{commentsCount ?? 0}</p>
        </div>
        {rol === 5 && (
          <div className="ClientInfoDetails__clientInfo__section">
            <h5
              className="ClientInfoDetails__link"
              onClick={() => window.open(`/auctionProfile/${auctionId}`)}
            >
              Ver Perfil de vendedor
            </h5>
          </div>
        )}
      </div>
    );
  };

  const renderClientExtraInfo = () => {
    return (
      <div className="ClientInfoDetails__clientExtraInfo">
        <h3 className="ClientInfoDetails__title">Sobre mí</h3>
        <div className="ClientInfoDetails__clientExtraInfo__section">
          <h4>
            <DescriptionIcon sx={iconStyle} />
            Descripción
          </h4>
          <p>{description ?? "Sin descripción"}</p>
        </div>
        <div className="ClientInfoDetails__clientExtraInfo__section">
          <h4>
            <LocationOnIcon sx={iconStyle} />
            Localización
          </h4>
          <p>{country ?? "No registrado"}</p>
        </div>
        <div className="ClientInfoDetails__clientExtraInfo__section">
          <h4>
            <PhoneIcon sx={iconStyle} />
            Contacto
          </h4>
          <p>{`${phone ?? "No registrado"} - ${email ?? "No registrado"}`}</p>
        </div>
        <div className="ClientInfoDetails__clientExtraInfo__section">
          <h4>
            <AddCardIcon sx={iconStyle} />
            Registró tarjeta
          </h4>
          <p>{payment_registred ? "Si" : "No"}</p>
        </div>
        <div className="ClientInfoDetails__clientExtraInfo__section">
          <h4>
            <ForumIcon sx={iconStyle} />
            Estado
          </h4>
          <p>{description ?? "Sin descripción"}</p>
        </div>
      </div>
    );
  };

  return (
    <Styles className="ClientInfoDetails">
      {(isLoading || isLoadingComments) && <CircularProgress />}
      <section className="ClientInfoDetails__clientSection">
        {renderClientInfo()}
        {renderClientExtraInfo()}
      </section>
      {renderComments()}
    </Styles>
  );
};

ClientInfoDetails.defaultProps = {};

export default ClientInfoDetails;
