import FlatList from "flatlist-react";
import React, { useState } from "react";
import { Auction } from "services/Auctioners/Auctioners.service.types";

import EmptyState from "../../emptyState/EmptyState";
import AuctionCard from "../auctionCard/AuctionCard";
import Styles from "./ListAuctions.styles";
import { ListAuctionsProps } from "./ListAuctions.types";

function ListAuctions(props: ListAuctionsProps) {
  const { auctionList, fromHome, className = "" } = props;
  const [searchTerm, setSearchTerm] = useState("");

  const renderSearch = () => (
    <div className="ListAuctions__container--input">
      <label className="ListAuctions__label" htmlFor="label">
        Buscar
      </label>
      <input
        value={searchTerm}
        className={`shadow appearance-none border rounded w-full
        py-3 px-3 text-gray-700 leading-tight focus:outline-none
        focus:shadow-outline focus:border-primary focus:ring-1
        focus:ring-primary ListAuctions__input`}
        onChange={e => {
          setSearchTerm(e.target.value);
        }}
      />
    </div>
  );

  return (
    <Styles className={`ListAuctions ${className}`}>
      {!fromHome ? renderSearch() : null}
      <div className="ListAuctions__container--items">
        <FlatList
          list={auctionList || []}
          renderItem={(auction: Auction, key: string | number) => (
            <AuctionCard key={key} auction={auction} />
          )}
          renderWhenEmpty={() => <EmptyState />}
          sortBy={["updated_at"]}
          sortDescending
          searchBy="title"
          searchTerm={searchTerm}
        />
      </div>
    </Styles>
  );
}

export default ListAuctions;
