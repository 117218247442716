import React from "react";
import { FieldValues, useForm, Controller } from "react-hook-form";

import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import TextField from "components/Globals/TextField/TextField";
import { handleValueDefault } from "containers/user/Profile.helpers";
import { useGetUserExtraData } from "services/Auth/Auth.services.hooks";
import { DirectionData } from "services/Auth/Auth.services.types";
import { addUserAddress } from "../../../services/Auth/Auth.service";
import { fail, successful } from "../../../utils/Swal";
import { myDirectionsForm } from "./MyDirection.helpers";
import Styles from "./MyDirection.styles";

const MyDirection = () => {
  const { data, refetch } = useGetUserExtraData();
  const { address: addressData = [] } = data ?? {};
  const { control, handleSubmit } = useForm();

  const FormHandler = (values: FieldValues) => {
    return addUserAddress({
      ...values
    } as DirectionData)
      .then(() => {
        refetch();
        successful("Registro de dirección exitoso");
      })
      .catch(error => {
        console.error(error);
        fail("Hubo un error al agregar la dirección");
      });
  };

  const renderDirections = () => {
    return (
      <div className="MyDirection__directions-container">
        <h2 className="MyDirection__directions__title">
          {addressData?.length > 0 ? "Mis direcciones" : "Agrega tu dirección"}
        </h2>
        <div className="MyDirection__directions">
          {addressData?.map((direction: any, idx: number) => (
            <div className="MyDirection__directions__item" key={idx}>
              <div className="MyDirection__directions__item-title text-primary">
                <h3>{direction.address + " " + direction.postal_code}</h3>
              </div>
              <p className="MyDirection__directions__item-address">
                {direction.country +
                  " " +
                  direction.city +
                  " " +
                  direction.state}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Styles className="MyDirection">
      {renderDirections()}
      <form onSubmit={handleSubmit(FormHandler)}>
        <div className="MyDirection__form">
          {myDirectionsForm.map((item, idx) => {
            const { label, placeholder, type, rules } = item ?? {};
            return (
              <Controller
                key={idx}
                name={label}
                control={control}
                rules={rules}
                defaultValue={handleValueDefault(type)}
                render={({ field, fieldState }) => (
                  <TextField
                    field={field}
                    fieldState={fieldState}
                    config={{
                      type: type,
                      label: placeholder,
                      variant: "standard",
                      margin: "dense",
                      fullWidth: true,
                      focused: true
                    }}
                  />
                )}
              />
            );
          })}
        </div>
        <div className="MyDirection__container--button">
          <ButtonLarge
            type="submit"
            className="hover:bg-hprimary"
            variant="contained"
          >
            Agregar Dirección
          </ButtonLarge>
        </div>
      </form>
    </Styles>
  );
};
export default MyDirection;
