import { AccountBox, Logout } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForumIcon from "@mui/icons-material/Forum";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Box, ListItemIcon, ListItemText } from "@mui/material";
import { Menu } from "@mui/material";
import { Toolbar, Tooltip } from "@mui/material";
import HoverNavItem from "components/Globals/HoverNavItem/HoverNavItem";
import MenuItem from "components/Globals/MenuItem/MenuItem";
import SearchBar from "components/Globals/SearchBar/SearchBar";
import { ROL } from "config/roles.config";
import useAuth from "context/auth/auth.hooks";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { updateCurrentAuction } from "redux/actions/auctionActions";
import { logoutSuccess } from "redux/actions/authActions";
// import history from "routes/history";
import { successful } from "utils/Swal";
import { useNavigate } from "react-router-dom";

import Button from "../../Globals/Button/Button";
import NavItem from "../../Globals/NavItem/NavItem";
import LoginModal from "../components/LoginModal/LoginModal";
import RegisterModal from "../components/RegisterModal/RegisterModal";
import { MENU } from "./DashboardNavbar.helper";
import { DashboardNavbarRoot } from "./DashboardNavbar.styles";
import { GuestLinksStyles } from "./DashboardNavbar.styles";
import { NavbarStyles } from "./DashboardNavbar.styles";
import { DashboardNavbarProps } from "./DashboardNavbar.types";
import { useCategories } from "services/Categories/categories.service.hooks";
import { Category } from "services/Categories/categories.service.types";

export const DashboardNavbar = (props: DashboardNavbarProps) => {
  const { onSidebarOpen, ...other } = props;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { openNotifications, setOpenNotifications } = useGlobals();
  const { cognitoLogout, isAnonymous } = useAuth();
  const { isLoading } = useGlobals();
  const { user } = useAppSelector(state => state.authReducer);
  const dispatch = useDispatch();
  const { data: categories } = useCategories();

  const navigate = useNavigate();
  const { rol } = user ?? {};
  const queryClient = useQueryClient();
  const [modalLogin, setModalLogin] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);

  const [initialEmail, setInitialEmail] = useState("");
  const [initialPassword, setInitialPassword] = useState("");
  const { reset } = useForm();

  const handleOpenUserMenu = (event: {
    currentTarget: React.SetStateAction<null>;
  }) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    cognitoLogout();
    dispatch(logoutSuccess(null));
    successful("Logout exitoso");
  };

  const handleEdit = () => {
    navigate("/panel/mi-perfil");
  };

  const handleChats = () => {
    navigate("/panel/chat");
  };

  const handleCalendar = () => {
    navigate("/calendario");
  };

  const handleRedirect = (route?: string) => {
    navigate(route ?? "/");
    return;
  };

  const onOpenCloseModalLogin = () => {
    setModalLogin(!modalLogin);
    setModalRegister(false);
    reset({
      email: "",
      password: ""
    });
  };

  const onOpenCloseModalRegister = (email?: string, password?: string) => {
    setModalRegister(!modalRegister);
    setModalLogin(false);
    if (email) setInitialEmail(email);
    if (password) setInitialPassword(password);
    reset({
      email: "",
      password: "",
      name: "",
      terms: false
    });
  };

  const clearInitialValues = () => {
    setInitialEmail("");
    setInitialPassword("");
  };

  const guestLinks = () => {
    return (
      <GuestLinksStyles>
        <div className="guestLinksStyle__container">
          <Button
            size="small"
            variant="contained"
            rounded="true"
            onClick={() => onOpenCloseModalLogin()}
            id="Login"
            className="button-login"
          >
            Iniciar Sesión
          </Button>

          <Button
            size="small"
            variant="outlined"
            rounded="true"
            onClick={() => onOpenCloseModalRegister()}
            id="Register"
          >
            Crear Cuenta
          </Button>
        </div>
      </GuestLinksStyles>
    );
  };

  const userLinks = () => {
    if (!user?.name || !user) return null;
    return (
      <>
        <Tooltip title="Abrir configuración">
          <Button
            variant="outlined"
            rounded="true"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleOpenUserMenu}
          >
            {user?.name}
          </Button>
        </Tooltip>
        <Menu
          sx={NavbarStyles.MenuStyles}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <AccountBox fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={NavbarStyles.MenuItemStyles}>
              Mi cuenta
            </ListItemText>
          </MenuItem>

          {user?.rol === ROL.ADMIN ? (
            <MenuItem
              onClick={() => {
                navigate("/admin/");
              }}
            >
              <ListItemIcon>
                <SupervisorAccountIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={NavbarStyles.MenuItemStyles}>
                Panel de administración
              </ListItemText>
            </MenuItem>
          ) : null}

          <MenuItem onClick={handleChats}>
            <ListItemIcon>
              <ForumIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={NavbarStyles.MenuItemStyles}>
              Mis chats
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={handleCalendar}>
            <ListItemIcon>
              <CalendarMonthIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={NavbarStyles.MenuItemStyles}>
              Calendario
            </ListItemText>
          </MenuItem>

          {rol !== 5 && (
            <MenuItem onClick={() => navigate("/registro-vendedor")}>
              <ListItemIcon>
                <PersonPinIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={NavbarStyles.MenuItemStyles}>
                Ser vendedor
              </ListItemText>
            </MenuItem>
          )}

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" style={{ color: "#ff7300" }} />
            </ListItemIcon>
            <ListItemText sx={NavbarStyles.MenuItemStyles}>
              {" "}
              Salir{" "}
            </ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <>
      <DashboardNavbarRoot sx={NavbarStyles.root} {...other}>
        <Toolbar disableGutters sx={NavbarStyles.ToolbarStyles}>
          <Tooltip title="Ir a inicio">
            <Box
              sx={NavbarStyles.BoxStyles.variant1}
              onClick={() => handleRedirect("/")}
            >
              <img src="/images/logo.png" alt="Logo LiveSubastas" />
            </Box>
          </Tooltip>

          <SearchBar />

          <Box sx={NavbarStyles.BoxStyles.variant3}>
            {!isLoading && (!isAnonymous ? userLinks() : guestLinks())}
          </Box>
        </Toolbar>
        {!isAnonymous ? (
          <Box
            sx={NavbarStyles.BoxStyles.variant4}
            style={{
              overflowX: "auto",
              height: 40,
              overflowY: "hidden",
              display: "flex",
              alignItems: "flex-end"
            }}
          >
            {MENU.map((item, index) => {
              const { title } = item;
              return (
                <NavItem
                  key={index}
                  onClick={() => {
                    if (title !== "Notificaciones") {
                      if (title === "Subastas") {
                        dispatch(updateCurrentAuction(undefined));
                        queryClient.invalidateQueries("auction");
                      }
                      handleRedirect(item?.to);
                    } else setOpenNotifications(!openNotifications);
                  }}
                  icon={item?.icon}
                >
                  {item.title}
                </NavItem>
              );
            })}
          </Box>
        ) : null}
        <Box component="div" sx={NavbarStyles.BoxStylesRoot}>
          <Toolbar
            component="nav"
            variant="dense"
            sx={NavbarStyles.ToolbarStylesRoot}
          >
            {categories?.map((c: Category) => (
              <HoverNavItem category={c} key={c.id}>
                {c.name}
              </HoverNavItem>
            ))}
          </Toolbar>
        </Box>
      </DashboardNavbarRoot>

      <RegisterModal
        modalRegister={modalRegister}
        setModalRegister={setModalRegister}
        onOpenCloseModalLogin={onOpenCloseModalLogin}
        initialEmail={initialEmail}
        initialPassword={initialPassword}
        clearInitialValues={clearInitialValues}
      />
      <LoginModal
        setModalLogin={setModalLogin}
        modalLogin={modalLogin}
        onOpenCloseModalRegister={onOpenCloseModalRegister}
      />
    </>
  );
};
