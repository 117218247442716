import { Auth, Amplify } from "aws-amplify";
import { CONSTANTS } from "config/constants";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React, { createContext, useCallback } from "react";
import { useEffect, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { loginSuccess, logoutSuccess } from "redux/actions/authActions";
// import history from "routes/history";
import { useNavigate } from "react-router-dom";

import { getUserData } from "services/Auth/Auth.service";
import { successful } from "utils/Swal";

import { AuthProviderProps as Props } from "./auth.context.types";
import { AuthProviderValue } from "./auth.context.types";

const { APP_CLIENT_ID, POOL_ID } = CONSTANTS;

export const AuthContext = createContext<AuthProviderValue>(
  {} as AuthProviderValue
);

const AuthProvider: React.FC<Props> = props => {
  const [forgotFlow, setForgotFlow] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(true);
  const [authError, setAuthError] = useState<Error | undefined>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { setIsLoading } = useGlobals();
  const { user } = useAppSelector(state => state.authReducer);

  useEffect(() => {
    Amplify.configure({
      Auth: {
        userPoolId: POOL_ID,
        userPoolWebClientId: APP_CLIENT_ID
      }
    });

    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.id) {
      setIsLoading(false);
      setIsAnonymous(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const refreshUser = useCallback(async () => {
    const user = await getUserData();
    if (user?.id) {
      dispatch(loginSuccess(user));
      queryClient.invalidateQueries();
    }
  }, [dispatch, queryClient]);

  const checkSession = useCallback(async () => {
    try {
      await Auth.currentSession();
      await refreshUser();
    } catch (error) {
      setIsAnonymous(true);
      setIsLoading(false);
    }
  }, [setIsLoading, refreshUser]);

  const cognitoLogout = useCallback(() => {
    if (!queryClient) return;
    Auth.signOut();
    setIsAnonymous(true);
    queryClient.removeQueries();
    dispatch(logoutSuccess(null));
    navigate("/");
  }, [queryClient, dispatch, navigate]);

  const resendVerify = useCallback(
    async (email: string) => {
      try {
        setIsLoading(true);
        await Auth.resendSignUp(email);
        successful("Codigo enviado");
      } catch (error) {
        setIsLoading(false);
        fail("Error al enviar el codigo");
      }
      setIsLoading(false);
    },
    [setIsLoading]
  );

  const verify = (email: string, code: number) => {
    return Auth.confirmSignUp(email, `${code}`);
  };

  const signIn = useCallback(
    async (email: string, password: string) => {
      try {
        setAuthError(undefined);
        setIsLoading(true);
        await Auth.signIn(email, password);
        checkSession();
      } catch (error) {
        setIsLoading(false);
        setAuthError(error);
        throw error;
      }
    },
    [setIsLoading, checkSession]
  );

  const signUp = useCallback(
    async (email: string, name: string, password: string) => {
      setAuthError(undefined);
      setIsLoading(true);
      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            name
          }
        });
        setIsLoading(false);
      } catch (error) {
        setAuthError(error);
        setIsLoading(false);
        throw error;
      }
    },
    [setIsLoading]
  );

  const value: AuthProviderValue = useMemo(() => {
    return {
      signUp,
      signIn,
      verify,
      resendVerify,
      cognitoLogout,
      authError,
      setAuthError,
      isAnonymous,
      forgotFlow,
      setForgotFlow,
      refreshUser
    };
  }, [
    authError,
    cognitoLogout,
    forgotFlow,
    isAnonymous,
    signIn,
    signUp,
    refreshUser,
    resendVerify
  ]);

  return (
    <AuthContext.Provider value={value}>
      <ContextDevTool context={AuthContext} id="auth" displayName="Auth" />
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
