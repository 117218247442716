import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import SkeletonText from "components/Globals/SkeletonComponent/SkeletonText/SkeletonText";
import IvsClientPlayer from "components/IvsClientPlayer/IvsClientPlayer";
import { CONSTANTS } from "config/constants";
import { useAppSelector } from "hooks";
import { updateLastComment } from "redux/actions/auctionActions";
import { updateCurrentPrice } from "redux/actions/auctionActions";
import { useGetCurrentItem } from "services/Auctioners/Auctioners.service.hooks";
import { useGetAuctionComments } from "services/Auctioners/Auctioners.service.hooks";
import { pusher } from "services/pusher/pusherApi";
import Styles from "./RightBids.styles";
import { RightBidsProps as Props, SubItem } from "./RightBids.types";

const { SKELETON_ARRAY, COMMENT_TYPE } = CONSTANTS;

function RightBids(props: Props) {
  const { offerData = [], items = [], children, currentItem } = props ?? {};
  const { isLoading } = props;
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);

  // Estado para saber si existe alguna oferta

  const { id: idString } = useParams<Record<string, string | undefined>>();
  const id = idString ? parseInt(idString, 10) : undefined; // fixing type incompatibility between auctionId and ID

  const { id: auctionId, stream, streamer } = currentAuction ?? {};
  const { data: comments = [], isLoading: isCommentsLoading } =
    useGetAuctionComments(auctionId ?? id);
  const [commentList, setCommentList] = useState(comments);
  const channel = pusher.subscribe(`liveauction${auctionId ?? id}`);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useAppSelector(state => state.authReducer);
  const { isLoading: isItemLoading, isFetching } = useGetCurrentItem(
    auctionId,
    items
  );
  const { sessionId } = stream ?? {};

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end"
    });
  };

  useEffect(() => {
    if (comments?.length > 0 && comments !== commentList) {
      dispatch(updateLastComment(comments[0]));
      setCommentList([...commentList, ...comments]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCommentsLoading]);

  const handleSetAdvice = useCallback(
    (data: any) => {
      const tempValue = data?.comment;
      dispatch(updateLastComment(tempValue));
    },
    [dispatch]
  );

  useEffect(() => {
    if (commentList) scrollToBottom();
  }, [commentList]);

  useEffect(() => {
    const currentPriceTemp =
      offerData?.[offerData?.length - 1]?.ActualPrice ??
      currentItem?.price_base ??
      "00";
    dispatch(updateCurrentPrice(currentPriceTemp?.toString()));
  }, [offerData, dispatch, currentItem]);

  useEffect(() => {
    return () => {
      pusher.unsubscribe(`liveauction${auctionId}`);
    };
  }, [auctionId]);

  channel?.bind("App\\Events\\InitializeAuctionEvent", () => {
    queryClient.invalidateQueries(["auction"]);
    queryClient.invalidateQueries(["currentItem"]);
    queryClient.invalidateQueries(["comments"]);
  });
  channel?.bind("App\\Events\\BidEvent", () => {
    queryClient.refetchQueries([currentItem?.id, "offer"]);
  });
  channel?.bind("App\\Events\\CommentAuctionNew", (data: any) => {
    setCommentList([...commentList, data?.comment]);
    handleSetAdvice(data);
  });
  channel?.bind("App\\Events\\InactiveItemEvent", (data: SubItem) => {
    if (!isFetching) {
      queryClient.cancelQueries([auctionId, "currentItem"]);
      queryClient.refetchQueries([auctionId, "currentItem"]);
    }
  });

  channel?.bind("App\\Events\\StreamAuctionEvent", () => {
    setTimeout(() => {
      document.location.reload();
    }, 5000);
  });

  const renderPrice = () => {
    if (isLoading || isItemLoading) return <SkeletonText fontSize={32} />;
    return null;
  };

  const renderChat = () => (
    <div className="RightBids--container-relative">
      {isLoading || isItemLoading ? (
        <>
          {SKELETON_ARRAY.map((_, idx) => (
            <SkeletonText key={idx} fontSize={24} />
          ))}
        </>
      ) : (
        <>
          {commentList?.map((comment, idx) => {
            const { type } = comment ?? {};
            if (type !== COMMENT_TYPE.simpleChat) {
              return (
                <span
                  className={`RightBids__span RightBids__span__full RightBids__span__full-${type}`}
                  key={idx}
                >
                  {comment?.comment}
                </span>
              );
            } else {
              const { user_id } = comment ?? {};
              const isOwner = user_id === user?.id;
              return (
                <span
                  key={idx}
                  className={`RightBids__span ${
                    isOwner ? "RightBids__span__right" : ""
                  }`}
                >
                  {comment?.comment}
                </span>
              );
            }
          })}
        </>
      )}
      <div ref={messagesEndRef} className="opacity-0 none" />
    </div>
  );

  return (
    <Styles className="RightBids">
      <div className="RightBids__container">
        {sessionId && streamer && (
          <div className="pt-2 w-full h-1/4">
            <IvsClientPlayer />
          </div>
        )}
        <div className="RightBids__container--header">{children}</div>
        <div className="RightBids__container--body">
          {renderPrice()}
          {renderChat()}
          {/* {advice?.type >= 0 && (
            <span
              className={`RightBids__span RightBids__span__full RightBids__span__full-${advice?.type}`}
            >
              {advice?.comment}
            </span>
          )} */}
        </div>
      </div>
    </Styles>
  );
}

export default RightBids;
