import React from "react";
import { useDispatch } from "react-redux";
import { Controller, FieldValues, useForm } from "react-hook-form";

import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import TextField from "components/Globals/TextField/TextField";
import { handleValueDefault } from "containers/user/Profile.helpers";
import useAuth from "context/auth/auth.hooks";
import { useAppSelector } from "hooks";
import { ProfileData } from "services/Auth/Auth.services.types";
import { updateUser } from "../../../redux/actions/authActions";
import { updateUserService } from "../../../services/Auth/Auth.service";
import { fail, successful } from "../../../utils/Swal";
import { myProfileForm } from "./myProfile.helper";
import Styles from "./MyProfile.styles";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { user } = useAppSelector(state => state.authReducer);
  const { refreshUser } = useAuth();

  const { control, handleSubmit } = useForm();

  const formHandler = (values: FieldValues) => {
    const formData: ProfileData = {
      ...values,
      type: 1
    };
    return updateUserService(formData)
      .then(response => {
        successful("Actualización de datos exitosa");
        refreshUser();
        const payload = { ...response.user, token: response.access_token };
        dispatch(updateUser(payload));
      })
      .catch(error => {
        console.error(error);
        fail("Hubo un error al actualizar sus datos");
      });
  };

  return (
    <Styles className="MyProfile">
      <form onSubmit={handleSubmit(formHandler)}>
        <div className="MyProfile__form--profile">
          {myProfileForm.map((item, idx) => {
            const { label, placeholder, type, rules, disabled } = item ?? {};

            return (
              <Controller
                key={idx}
                name={label}
                control={control}
                rules={rules}
                defaultValue={
                  user
                    ? user[label as keyof typeof user]
                    : handleValueDefault(type)
                }
                render={({ field, fieldState }) => (
                  <TextField
                    field={field}
                    fieldState={fieldState}
                    config={{
                      type: type,
                      label: placeholder,
                      variant: "standard",
                      margin: "dense",
                      fullWidth: true,
                      focused: true,
                      disabled: disabled
                    }}
                  />
                )}
              />
            );
          })}
        </div>
        <div className="MyProfile__container--button">
          <ButtonLarge
            type="submit"
            className="hover:bg-hprimary"
            size="large"
            variant="contained"
          >
            Actualizar
          </ButtonLarge>
        </div>
      </form>
    </Styles>
  );
};
export default MyProfile;
