import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, InputLabel } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import Button from "components/Globals/Button/Button";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import ErrorComponent from "components/Globals/ErrorComponent/ErrorComponent";
import InputBase from "components/Globals/InputBase/InputBase";
import useAuth from "context/auth/auth.hooks";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React, { useEffect, useState } from "react";
import { useForm, FieldValues } from "react-hook-form";
import PasswordChecklist from "react-password-checklist";
import { useDispatch } from "react-redux";
import { resetAuctionsRedux } from "redux/actions/auctionActions";
// import history from "routes/history";
import { useNavigate } from "react-router-dom";

import { useRegister } from "services/Auth/Auth.services.hooks";
import { RegisterData } from "services/Auth/Auth.services.types";

import ForgotPassword from "../ForgotPassword/ForgotPassword";
import { messages, schema } from "./RegisterModal.helpers";
import Styles from "./RegisterModal.styles";
import { RegisterModalProps as Props } from "./RegisterModal.types";

const RegisterModal: React.FC<Props> = props => {
  const {
    setModalRegister,
    modalRegister,
    onOpenCloseModalLogin,
    initialEmail,
    initialPassword,
    clearInitialValues
  } = props;

  const [step, setStep] = useState(0);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutateAsync } = useRegister();
  const { setIsLoading, isLoading } = useGlobals();
  const { signUp, verify: verifyUser, resendVerify } = useAuth();
  const { authError, setAuthError, signIn } = useAuth();
  const { forgotFlow, setForgotFlow } = useAuth();
  const errorMessage = authError?.message;
  const [pass, setPass] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const { user: reduxUser } = useAppSelector(state => state.authReducer);

  useEffect(() => {
    if (!modalRegister) {
      setPass("");
      setChecked(false);
    }
  }, [modalRegister]);

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    getValues,
    trigger,
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (initialEmail && initialPassword) {
      setValue("email", initialEmail);
      setValue("password", initialPassword);
      clearInitialValues?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEmail, initialPassword]);

  const FormRegister = async (values: FieldValues) => {
    const { email, password } = values;
    setAuthError(undefined);
    const name = email.substring(0, email.indexOf("@"));
    try {
      await signUp(email, name, password);
      if (!authError) {
        setStep(1);
      }
    } catch (error) {
      const errorMessage =
        error?.name === "UsernameExistsException"
          ? "El correo electrónico ya está registrado en el sistema."
          : "Error al registrar el usuario en el sistema.";
      setErrorMessageText(errorMessage);
      throw new Error("Error al registrarse.");
    }
  };

  const VerifyCode = async (values: FieldValues) => {
    const { email, verify, password } = values;
    const code = `${verify}`.length === 6 ? verify : `0${verify}`;
    const name = email.substring(0, email.indexOf("@"));
    const data = {
      ...values,
      name,
      rol: 1
    };
    setAuthError(undefined);
    setIsLoading(true);
    try {
      await verifyUser(email, code);
      await mutateAsync(data as RegisterData);
      dispatch(resetAuctionsRedux());
      await signIn(email, password);
      reset();
      setIsLoading(false);
    } catch (error) {
      const errMessage = error.message.includes("Invalid verification")
        ? "Error al validar el código de verificación, vuelva a intentarlo."
        : "Error al registrar el usuario en el sistema.";
      setErrorMessageText(errMessage);
      setAuthError(error);
      setIsLoading(false);
    }
  };

  const renderEmailInput = (
    <FormControl
      variant="standard"
      className="RegisterModal__component--form-control"
    >
      <InputLabel
        shrink
        htmlFor="register-email"
        className="RegisterModal__component--input"
      >
        Correo electrónico
      </InputLabel>
      <InputBase
        id="register-email"
        placeholder="Ingrese correo electrónico"
        {...register("email")}
        error={!!errors.email}
        autoComplete="off"
        type="text"
      />
      <ErrorComponent
        validation={!!errors.email}
        content={errors.email?.message}
      />
    </FormControl>
  );

  const renderForms = () => {
    switch (step) {
      case 0:
        return renderFirstStep();
      case 1:
        return renderSecondStep();
      default:
        return renderFirstStep();
    }
  };

  const renderHelperTexts = () => {
    return (
      <div className="RegisterModal__textContainer">
        <p className="RegisterModal__text RegisterModal__text--register">
          ¿Ya tienes una cuenta?.{" "}
          <button
            id="GoToLogin"
            onClick={onOpenCloseModalLogin}
            className="RegisterModal__text--register-action text-primary"
          >
            Entrar
          </button>
        </p>
        <p className="RegisterModal__text RegisterModal__text--register">
          ¿Olvidaste tu contraseña?.{" "}
          <button
            id="GoToLogin"
            onClick={() => setForgotFlow(true)}
            className="RegisterModal__text--register-action text-primary"
          >
            Recupérala aquí
          </button>
        </p>
        {step === 1 && (
          <p className="RegisterModal__text RegisterModal__text--register">
            ¿Querés registrar otro usuario?.{" "}
            <button
              id="GoToLogin"
              onClick={() => {
                setChecked(false);
                reset();
                setErrorMessageText("");
                setStep(0);
              }}
              className="RegisterModal__text--register-action text-primary"
            >
              Registrar nuevo usuario
            </button>
          </p>
        )}
      </div>
    );
  };

  const renderFirstStep = () => {
    return (
      <form
        onSubmit={handleSubmit(FormRegister)}
        className="RegisterModal__component--form"
        autoComplete="off"
      >
        {renderEmailInput}

        <FormControl
          variant="standard"
          className="RegisterModal__component--form-control"
        >
          <InputLabel
            shrink
            htmlFor="new-password"
            className="RegisterModal__component--input"
          >
            Contraseña
          </InputLabel>
          <InputBase
            id="new-password"
            placeholder="Ingrese contraseña"
            {...register("password")}
            type="password"
            error={!!errors.password}
            onChange={e => setPass(e.currentTarget.value)}
            key="register-password"
            autoComplete="new-password"
          />
          <ErrorComponent
            validation={!!errors.password}
            content={errors.password?.message}
          />
        </FormControl>

        <PasswordChecklist
          rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
          minLength={8}
          value={pass}
          valueAgain={pass}
          onChange={isValid => setIsValid(isValid)}
          messages={messages}
          className="RegisterModal__passwordCheck"
          iconSize={12}
          invalidColor="#B0C4D8"
          style={{ lineHeight: 1, paddingBottom: 1 }}
        />

        <div>
          <label htmlFor="terms" className="RegisterModal__container--checkbox">
            <input
              className="RegisterModal__component--checkbox"
              type="checkbox"
              id="terms"
              {...register("terms", { required: true })}
              onChange={e => setChecked(e.target.checked)}
              checked={checked}
            />
            <span
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                window.open(
                  `https://www.fleetster.net/legal/standard-terms-and-conditions.pdf`,
                  "_blank"
                );
              }}
              className="RegisterModal__text--checkbox text-primary underline"
            >
              Acepto los términos, políticas de privacidad.
            </span>
          </label>
        </div>
        <div className="RegisterModal__container--form-button">
          <ButtonLarge
            type="submit"
            variant="contained"
            disabled={!checked || !isDirty || !isValid}
            loading={isLoading}
            size="large"
          >
            Registrarse
          </ButtonLarge>
        </div>

        {renderHelperTexts()}
      </form>
    );
  };

  const renderSecondStep = () => {
    return (
      <form
        onSubmit={handleSubmit(VerifyCode)}
        className="RegisterModal__component--form"
      >
        {renderEmailInput}

        <FormControl
          variant="standard"
          className="RegisterModal__component--form-control"
        >
          <InputLabel
            shrink
            htmlFor="verify"
            className="RegisterModal__component--input"
          >
            Código de verificación
          </InputLabel>
          <InputBase
            id="verify"
            placeholder="Ingrese el código de verificación"
            {...register("verify")}
            error={!!errors.verify}
            type="number"
          />
          <ErrorComponent
            validation={!!errors.verify}
            content={errors.verify?.message}
          />
        </FormControl>

        <div>
          <label
            htmlFor="resend-code"
            className="RegisterModal__container--checkbox"
          >
            <Button
              onClick={async () => {
                const valid = await trigger("email");
                if (valid) resendVerify(getValues().email);
              }}
              loading={isLoading}
              id="resend-code"
            >
              Reenviar código de verificación
            </Button>
          </label>
        </div>

        <div className="RegisterModal__container--form-button">
          <ButtonLarge type="submit" variant="contained" loading={isLoading}>
            Verificar
          </ButtonLarge>
        </div>
        {renderHelperTexts()}
      </form>
    );
  };

  const renderTitle = !forgotFlow ? (
    <h1 className="RegisterModal__text RegisterModal__text--title">
      Regístrate{" "}
      <span className="RegisterModal__text RegisterModal__text--subtitle">
        {" "}
        y comienza a ofertar
      </span>
    </h1>
  ) : (
    <h1 className="RegisterModal__text RegisterModal__text--title">
      Recuperar contraseña
    </h1>
  );

  const renderForm = () => {
    return (
      <div className="RegisterModal__container--form">
        <div className="RegisterModal__container--form-header">
          <img
            src="/images/logo.png"
            className="RegisterModal__container--logo"
            alt="logo"
          />
          <div>{renderTitle}</div>
        </div>

        <div>
          {errorMessage && errorMessageText !== "" && (
            <p className="RegisterModal__component--error">
              {errorMessageText}
            </p>
          )}
          {!forgotFlow ? (
            renderForms()
          ) : (
            <ForgotPassword handleModal={() => setForgotFlow(false)} />
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (reduxUser?.id && modalRegister) {
      const route = "/panel/mi-perfil";
      navigate(route);
      setModalRegister(false);
    }
  }, [modalRegister, reduxUser, setModalRegister, navigate]);

  useEffect(() => {
    if (errorMessage && errorMessage.includes("Network error")) {
      setStep(0);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!modalRegister) {
      reset();
      setErrorMessageText("");
      setAuthError(undefined);
    }
  }, [modalRegister, reset, setAuthError]);

  useEffect(() => {
    if (errorMessage && errorMessage.includes("User is not confirmed.")) {
      setStep(1);
      document.getElementById("resend-code")?.click();
    }
  }, [errorMessage, getValues, onOpenCloseModalLogin, signIn]);

  return (
    <CustomModal
      isOpen={modalRegister}
      onClose={() => setModalRegister(!modalRegister)}
      ariaLabelledby="Formulario de inicio de sesión"
      className="RegisterModal__modal"
    >
      <Styles className="RegisterModal">
        <div className="RegisterModal__container--components">
          <div className="RegisterModal__container--image" />
          {renderForm()}
        </div>
      </Styles>
    </CustomModal>
  );
};

RegisterModal.defaultProps = {};

export default RegisterModal;
