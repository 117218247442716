import React from "react";
import { useDispatch } from "react-redux";
// import history from "../../routes/history";
import { useNavigate } from "react-router-dom";

import Button from "components/Globals/Button/Button";
import { Auction } from "services/Auctioners/Auctioners.service.types";
import { updateCurrentAuction } from "redux/actions/auctionActions";

type LiveAuctionsProps = {
  liveAuction: Auction;
};

const FeaturedLiveAuction = ({ liveAuction }: LiveAuctionsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { title, auction_house, url_logo_1, id, streamer } = liveAuction;
  const { name_business, logo } = auction_house ?? {};

  const liveCount = undefined;

  return (
    <div className="p-3 relative font-Poppins-Rg shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] rounded-[25px] flex flex-col items-center justify-center">
      <div className="flex font-Poppins-Rg text-sm font-normal gap-x-2 absolute top-5 left-0 bg-new-200 text-white rounded-r-full py-2 px-3">
        {streamer ? (
          <img src="/images/home/camera.svg" alt="Icono cámara" />
        ) : null}
        <span className="">En vivo</span>
        {liveCount ? (
          <>
            <img src="/images/home/eye.svg" alt="Icono ojo" />
            <span>{liveCount}</span>
          </>
        ) : null}
      </div>
      <div
        className="bg-center w-full p-3 rounded-3xl aspect-square flex justify-end bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${url_logo_1})`
        }}
      >
        <button className="p-2 hover:scale-110 transition duration-300 ease-in-out bg-[rgba(3,3,3,0.5)] rounded-full w-fit h-fit flex items-center justify-center">
          <img src="/images/home/heart.svg" alt="Icono corazón" />
        </button>
      </div>
      <div className="flex w-full items-center gap-x-3">
        {logo ? (
          <img src={logo} alt="Logo subasta en vivo" className="w-10 h-10" />
        ) : null}
        <div className="flex flex-col pt-4 pb-2 gap-y-1">
          <span className="font-Poppins-Md text-base text-black leading-tight">
            {title}
          </span>
          <span className="text-sm font-Poppins-Lt text-black pb-3">
            {name_business}
          </span>
        </div>
      </div>
      <Button
        size="small"
        variant="outlined"
        rounded="true"
        id="Login"
        className="font-Poppins-Rg shadow-none text-sm py-1 w-full px-6 rounded-full"
        onClick={() => {
          dispatch(updateCurrentAuction(liveAuction));
          navigate(`/auction/${id}`);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        ¡Ofertar ahora!
      </Button>
    </div>
  );
};

export default FeaturedLiveAuction;
