import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
// import history from "../../routes/history";
import { useNavigate } from "react-router-dom";

import Button from "components/Globals/Button/Button";
import { Auction } from "services/Auctioners/Auctioners.service.types";
import { updateCurrentAuction } from "redux/actions/auctionActions";

type FeaturedActionsProps = {
  auction: Auction;
  onAuctionStarted?: (auction: Auction) => void;
};

const FeaturedAuction = ({
  auction,
  onAuctionStarted
}: FeaturedActionsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState<Dayjs>(dayjs());

  const {
    title: auctionName,
    date,
    auction_house,
    date_end,
    date_ini,
    url_logo_1,
    id
  } = auction;
  const { name_business, logo } = auction_house ?? {};

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs();
      setCurrentTime(now);

      const startTime = dayjs(date_ini ?? date);
      const hasStarted = startTime.isBefore(now);

      if (hasStarted) {
        clearInterval(interval);
        if (onAuctionStarted) {
          onAuctionStarted(auction); // Llama al callback cuando la subasta comienza
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date_ini, date, onAuctionStarted, auction]);

  const startTime = date_ini ?? date;

  const futureDate = dayjs(startTime);

  const hasStarted = futureDate.isBefore(currentTime);

  const diffInMilliseconds = futureDate.diff(currentTime);
  const days = Math.floor(diffInMilliseconds / 1000 / 60 / 60 / 24);
  const hours = Math.floor((diffInMilliseconds / 1000 / 60 / 60) % 24);
  const minutes = Math.floor((diffInMilliseconds / 1000 / 60) % 60);
  const seconds = Math.floor((diffInMilliseconds / 1000) % 60);

  const formattedDiff = `${days > 0 ? days + "d " : ""}${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}h`;

  return (
    <div className="p-3 relative snap-center font-Poppins-Rg w-[650px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] rounded-[10px] flex bg-white flex-col items-center justify-center">
      <div
        className="min-h-[280px] bg-cover bg-center w-full p-2 rounded-t-xl"
        style={{ backgroundImage: `url(${url_logo_1})` }}
      >
        {!hasStarted ? (
          <div className="rounded-xl min-w-[165px] bg-black bg-opacity-50 text-[#fafafa] flex p-2 px-4 gap-x-3 w-fit">
            <img src="/images/home/timer.svg" alt="Icono reloj" />
            <div className="flex flex-col">
              <span className="text-[12px] font-Poppins-Lt font-light">
                Comienza en:
              </span>
              <span className="font-normal text-lg">{formattedDiff}</span>
            </div>
          </div>
        ) : null}
      </div>
      {logo ? (
        <div className="bg-white flex overflow-hidden items-center justify-center rounded-bl-[30px] absolute top-0 right-0 w-[200px] h-[60px]">
          <img src={logo} alt="Logo subastador" className="max-h-[50px]" />
        </div>
      ) : null}
      <div className="flex justify-between w-full pt-3">
        <div className="flex flex-col">
          <span className="text-xl font-semibold text-new-100">
            {auctionName}
          </span>
          <span className="text-sm font-normal text-new-100 pb-3">
            {name_business}
          </span>
          <span className="text-xs text-[#465670] pb-1">
            Comienza el{" "}
            {dayjs(startTime).format("DD [de] MMMM [de] YYYY [a las] HH:mm[h]")}
          </span>
          {date_end && (
            <span className="text-xs text-[#465670] ">
              Finaliza el{" "}
              {dayjs(date_end).format(
                "DD [de] MMMM [de] YYYY [a las] HH:mm[h]"
              )}
            </span>
          )}
        </div>
        <div className="flex items-end">
          <Button
            size="small"
            variant="contained"
            rounded="true"
            id="Login"
            className="font-Poppins-Rg text-[#fafafa] shadow-none text-sm p-3 px-6 rounded-full"
            onClick={() => {
              dispatch(updateCurrentAuction(auction));
              navigate(`/auction/${id}`);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Registrate
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedAuction;
