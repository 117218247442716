import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SellerData, User } from "services/Auth/Auth.services.types";

interface AuthState {
  user: User;
  directions: [];
  payments: [];
  sellerInfo: SellerData;
  currency: {
    id?: string;
  };
}

const initialState: AuthState = {
  user: {} as User,
  directions: [],
  payments: [],
  sellerInfo: {} as SellerData,
  currency: {}
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    logoutSuccess: (state, action) => {
      state.user = {} as User;
      state.directions = [];
      state.payments = [];
      state.sellerInfo = {} as SellerData;
      state.currency = {};
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload, token: "" };
    },
    updateUserDirections: (state, action) => {
      state.directions = { ...state.directions, ...action.payload, token: "" };
    },
    updateUserPayments: (state, action) => {
      state.payments = { ...state.payments, ...action.payload, token: "" };
    },
    updateSellerInfo: (state, action) => {
      state.sellerInfo = { ...state.sellerInfo, ...action.payload, token: "" };
    },
    updateUserCurrency: (state, action) => {
      state.currency = { ...action.payload };
    },
    resetAuthRedux: (state, _) => {
      state.user = initialState.user;
      state.directions = initialState.directions;
      state.payments = initialState.payments;
      state.sellerInfo = initialState.sellerInfo;
      state.currency = initialState.currency;
    }
  }
});
