import { CircularProgress } from "@mui/material";
import useGlobals from "context/globals/globals.hooks";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom"; // Usar Navigate y Outlet

const RouteUser = () => {
  const { user } = useSelector(state => state.authReducer);
  const { isLoading } = useGlobals();

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return user?.rol ? <Outlet /> : <Navigate to="/" />;
};

export default RouteUser;
