import { ThemeProvider } from "@mui/material";
import { createTheme, StyledEngineProvider } from "@mui/material";
import { ConfigProvider } from "antd";
import { variables } from "config/variables";
import AuthProvider from "context/auth/auth.context";
import GlobalsProvider from "context/globals/globals.context";
import React, { useRef } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "redux/store/store";
import RouterComponent from "routes/Router";
import es from "antd/lib/locale/es_ES";

const { palette } = variables;

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary
    },
    secondary: {
      main: palette.blackCharcoal
    }
  }
});

const App = () => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 15 * 1000 * 60,
          retry: 3
        }
      }
    });
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <GlobalsProvider>
                <Router>
                  <AuthProvider>
                    <ConfigProvider locale={es}>
                      <RouterComponent />
                    </ConfigProvider>
                  </AuthProvider>
                </Router>
              </GlobalsProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};
export default App;
