import React from "react";
import { Tab } from "@headlessui/react";
import { useSelector } from "react-redux";

import Layout from "components/Layout/Layout";
import ListFavorites from "../../components/Auctioners/listFavorites/ListFavorites";
import { RootState } from "redux/store/store";

const FavoritesScreen = () => {
  const { user } = useSelector((state: RootState) => state.authReducer);

  const renderTabs = () => {
    return (
      <Tab.List className="mt-12 space-x-12 font-Poppins-Lt text-[18px] text-center">
        <Tab
          className={({ selected }) =>
            selected ? "text-primary" : "text-black"
          }
        >
          Mis favoritos
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
      </Tab.List>
    );
  };

  return (
    <Layout>
      <div
        className="px-32 mt-16 font-Poppins-Rg"
        style={{
          margin: "5em auto",
          width: "calc(100% - 40px)",
          maxWidth: "1390px",
          marginTop: "4.5em"
        }}
      >
        <div className="flex items-start">
          <h4
            className="font-Poppins-El text-[40px] tracking-wider font-extrabold	text-black"
            style={{ fontVariant: "all-small-caps" }}
          >
            Favoritos
          </h4>
        </div>
        <Tab.Group>
          {renderTabs()}
          <Tab.Panels className="px-16 mt-16 mb-16">
            <Tab.Panel>
              <ListFavorites />
            </Tab.Panel>
            {user && user.rol === 5 ? <Tab.Panel></Tab.Panel> : null}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Layout>
  );
};
export default FavoritesScreen;
