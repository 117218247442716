import React from "react";

import TextFieldStyles from "./InputText.styles";
import { InputTextProps } from "./InputText.types";

const InputText = (props: InputTextProps) => {
  const { item, errors, defaultValue, register } = props ?? {};
  const { disabled, variant = "standard" } = props ?? {};
  const { label, type, placeholder, required, focused } = item ?? {};

  return (
    <TextFieldStyles
      label={placeholder}
      variant={variant}
      required={required}
      error={errors?.label}
      defaultValue={defaultValue ?? ""}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      focused={focused}
      {...register(label, { required })}
    />
  );
};

export default InputText;
