import { Avatar, MenuItem } from "@mui/material";
import useGlobals from "context/globals/globals.hooks";
import React from "react";
// import history from "routes/history";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useMarkNotificationAsRead } from "services/notifications/notifications.service.hooks";

import Styles from "./NotificationCard.styles";
import { NotificationCardProps as Props } from "./NotificationCard.types";

dayjs.extend(relativeTime);

const NotificationCard: React.FC<Props> = props => {
  const { notification } = props;
  const {
    message,
    owner_name,
    owner_photo,
    readed,
    title,
    id,
    category,
    amount,
    created_at
  } = notification ?? {};

  const { setCurrentNotification } = useGlobals();
  const { mutateAsync, reset } = useMarkNotificationAsRead();
  const navigate = useNavigate();

  const handleMarkAsRead = async () => {
    setCurrentNotification(notification);
    if (!id) return;
    await mutateAsync(id);
    reset();
    navigate("/panel/notificaciones");
  };

  return (
    <Styles
      className={`NotificationCard`}
      readed={readed}
      onClick={handleMarkAsRead}
    >
      <MenuItem>
        <Avatar
          alt="Remy Sharp"
          src={owner_photo ?? "/images/LiveLogoRounded.png"}
          className="NotificationCard__avatar"
        />
        <div className="NotificationCard__content">
          <h2>{`${owner_name ?? "Usuario desconocido"}: ${title}`}</h2>
          <p>{message}</p>
          {category && (
            <p className="NotificationCard__category">Categoría: {category}</p>
          )}
          {amount && (
            <p className="NotificationCard__amount">Monto: ${amount}</p>
          )}
          <span className="NotificationCard__time">
            {dayjs(created_at).fromNow()}
          </span>
        </div>
      </MenuItem>
    </Styles>
  );
};

NotificationCard.defaultProps = {};

export default NotificationCard;
