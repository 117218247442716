import React, { useEffect } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";

import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { variables } from "config/variables";
import {
  StyledTableRow,
  StyledTableCell
} from "containers/mybids/MyBids.styles";
import {
  useManageAuctionAccess,
  useFetchAuctionApplies
} from "services/auctionAccess/auctionAccess.service.hooks";
import { useCreateChat } from "services/userChat/userChat.service.hooks";
import { fail, successful } from "utils/Swal";
import { ApplicantListProps as Props } from "./ApplicantList.types";
import Styles from "./ApplicantList.styles";

const { palette } = variables;

const ApplicantList: React.FC<Props> = props => {
  const { id } = useParams<Record<string, string | undefined>>();
  const auctionId = id ? parseInt(id, 10) : undefined;

  const { data: applyList = [] } = useFetchAuctionApplies(auctionId);
  const { isLoading, refetch } = useFetchAuctionApplies(auctionId);
  const { mutateAsync, reset } = useManageAuctionAccess();
  const { mutateAsync: createChatMutation, reset: createChatReset } =
    useCreateChat();

  const handleManageAccess = async (accessId: number, accepted: boolean) => {
    try {
      await mutateAsync({ auctionId: accessId, accessId, access: accepted });
      reset();
      refetch();
      successful("Permisos actualizados con éxito");
    } catch (error) {
      fail("Ocurrió un error al manejar los permisos del usuario.");
    }
  };

  const handleCreateChat = async (uid: number) => {
    try {
      createChatMutation(uid);
      createChatReset();
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.open(`/panel/chat`);
      //TODO: set current chat id on redux
    } catch (error) {
      fail("Error al crear el chat con el usuario");
    }
  };

  const renderActions = (accessId: number, userId: number) => {
    return (
      <>
        <Tooltip title="Aceptar">
          <CheckCircleOutlineIcon
            fontSize="medium"
            sx={{
              marginRight: 1,
              color: palette.chipSuccess,
              cursor: "pointer"
            }}
            onClick={() => handleManageAccess(accessId, true)}
          />
        </Tooltip>
        <Tooltip title="Rechazar">
          <DoDisturbIcon
            fontSize="medium"
            sx={{
              color: palette.chipOrange,
              marginRight: 1,
              cursor: "pointer"
            }}
            onClick={() => handleManageAccess(accessId, false)}
          />
        </Tooltip>
        <Tooltip title="Chat entre comprador y vendedor">
          <ChatIcon
            fontSize="medium"
            sx={{
              marginRight: 1,
              color: palette.chipSuccess,
              cursor: "pointer"
            }}
            onClick={() => handleCreateChat(userId)}
          />
        </Tooltip>
      </>
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Styles className="ApplicantList">
      {isLoading ? <CircularProgress /> : null}
      <h2 className="ApplicantList__title">Lista de aplicantes</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {/* <StyledTableCell size="small">Imagen</StyledTableCell> */}
              <StyledTableCell size="small" align="left">
                Nombre
              </StyledTableCell>
              <StyledTableCell align="left">Apellidos</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Ocupación</StyledTableCell>
              <StyledTableCell align="left">
                Fecha de aplicación
              </StyledTableCell>
              <StyledTableCell align="left">
                Registró tarjeta de crédito
              </StyledTableCell>
              <StyledTableCell align="left">Registró dirección</StyledTableCell>
              <StyledTableCell align="center" size="small" width={16}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applyList?.map(apply => {
              const { name, surname, ocupation, id } = apply ?? {};
              const { created_at, state, email, user_id } = apply ?? {};
              const { payment_registred, address_registred } = apply ?? {};

              let pendant = "Pendiente";

              switch (state ?? 0) {
                case 1:
                  pendant = "Pendiente";
                  break;
                case 2:
                  pendant = "Aprovado";
                  break;
                case 3:
                  pendant = "Rechazado";
                  break;
                case 4:
                  pendant = "Rechazado";
                  break;
                default:
                  pendant = "Rechazado";
                  break;
              }

              return (
                <StyledTableRow key={id}>
                  {/* <StyledTableCell component="th" scope="row">
                    <Avatar alt="Lote Image" src={photo} variant="rounded" />
                  </StyledTableCell> */}
                  <StyledTableCell align="left">{name ?? ""}</StyledTableCell>
                  <StyledTableCell align="left">
                    {surname ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">{email ?? ""}</StyledTableCell>
                  <StyledTableCell align="left">
                    {ocupation ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dayjs(created_at).format("DD/MM/YYYY HH:mm a")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {payment_registred ? "Si" : "No"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {address_registred ? "Si" : "No"}
                  </StyledTableCell>

                  <StyledTableCell align="right" size="small">
                    <Chip
                      label={pendant}
                      variant={pendant === "Pendiente" ? "outlined" : "filled"}
                      color={
                        pendant === "Aprovado"
                          ? "success"
                          : pendant === "Pendiente"
                          ? "primary"
                          : "error"
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    {renderActions(id, user_id)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Styles>
  );
};

ApplicantList.defaultProps = {};

export default ApplicantList;
