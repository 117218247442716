import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// Config
import { ROL } from "../../config/roles.config";

const RouteSeller = () => {
  const { user } = useSelector(state => state.authReducer);

  return user?.rol === ROL.SELLER ? <Outlet /> : <Navigate to="/" />;
};

export default RouteSeller;
