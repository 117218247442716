import React from "react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";

import CardImage from "../../Globals/Card/CardImage/CardImage";
import CarouselCustom from "../../Globals/CarouselCustom/CarouselCustom";
import { CarouselClientsProps } from "./CarouselClient.types";

const CarouselClients = (props: CarouselClientsProps) => {
  const { data } = props;

  return (
    <CarouselCustom
      slidesPerView={1}
      spaceBetween={30}
      effect={"fade"}
      navigation={false}
      pagination={{
        clickable: true
      }}
      loop={true}
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      height={736}
      autoplay={{ delay: 5000, disableOnInteraction: false }}
    >
      {data?.map((row: any, index: number) => (
        <SwiperSlide key={index}>
          <CardImage
            date={row?.date}
            title={row?.title}
            author={row?.author}
            src={row?.src}
            link={row?.link}
          />
        </SwiperSlide>
      ))}
    </CarouselCustom>
  );
};

export default CarouselClients;
