import styled from "styled-components";

import { variables } from "../../../config/variables";
import { CarouselCustomStyledProps } from "./CarouselCustom.types";

const { palette } = variables;

const Styles = styled.div<CarouselCustomStyledProps>`
  .swiper {
    width: 100%;
    // height: ${props => props.height}px;
    height: 675px;
    margin-left: auto;
    margin-right: auto;
    --swiper-navigation-color: ${palette.primary};
    --swiper-pagination-color: ${palette.dividerColor};
  }

  .swiper-slide {
    filter: none !important;
    background: none !important;
  }

  img {
    filter: none !important;
    background: none !important;
    opacity: 1 !important;
  }

  .swiper-wrapper {
    height: 675px;
  }

  .swiper-slide {
    width: ${props => props.width}px;
  }

  .swiper-pagination-bullet {
    width: 50px;
    height: 5px;
    border-radius: 25px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: #d9d9d9;
  }

  .swiper-pagination-bullet-active {
    color: #fff;
    background: #ff7300;
  }
`;

export default Styles;
