import IvsPlayer from "components/IvsPlayer/IvsPlayer";
import AdminDashboard from "containers/AdminDashboard/AdminDashboard.container";
import ApplicantList from "containers/ApplicantList/ApplicantList.container";
import Calendar from "containers/Calendar/Calendar.container";
import Home from "containers/Home";
import Notifications from "containers/Notifications/Notifications.container";
import SellerProfileContainer from "containers/SellerProfile/SellerProfile.container";
import UserChat from "containers/UserChat/UserChat.container";
import Wishes from "containers/Wishes/Wishes.container";
import Auctioners from "containers/auctioners/Auctioners";
import RegisterSeller from "containers/auctioners/RegisterSeller";
import Auction from "containers/auctions/Auction";
import Favorites from "containers/favoritesScreen/Favorites.screen";
import Item from "containers/itemScreen/Item.screen";
import LiveAuction from "containers/liveAuction/LiveAuction";
import Login from "containers/login/Login";
import MyBids from "containers/mybids/MyBids";
import Profile from "containers/user/Profile";
import React from "react";
import { Route, Routes } from "react-router-dom";

import RouteAdmin from "./PrivateRoutes/RouteAdmin";
import RouteSeller from "./PrivateRoutes/RouteSeller";
import RouteUser from "./PrivateRoutes/RouteUser";

const RouterComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/registro-vendedor" element={<RegisterSeller />} />
      <Route path={`/auction/:id`} element={<Auction />} />
      <Route path={`/live-auction/:id`} element={<LiveAuction />} />
      <Route path={`/live-auction/stream/:id`} element={<IvsPlayer />} />
      <Route path={`/item/:auctionId/:id`} element={<Item />} />
      <Route
        path={`/auctionProfile/:id`}
        element={<SellerProfileContainer />}
      />
      <Route path="/panel/subastas" element={<Auctioners />} />
      <Route path="/calendario" element={<Calendar />} />
      <Route path="/panel/deseos" element={<Wishes />} />
      <Route path="/panel/mis-pujas" element={<MyBids />} />

      {/* Private Registered Routes */}
      <Route path="/panel" element={<RouteUser />}>
        <Route path="mi-perfil" element={<Profile />} />
        <Route path="subastas/:edit" element={<Auctioners />} />
        <Route path="favoritos" element={<Favorites />} />
        <Route path="chat" element={<UserChat />} />
        <Route path="notificaciones" element={<Notifications />} />
      </Route>

      {/* Private Seller Routes */}
      <Route path="/seller" element={<RouteSeller />}>
        <Route path="" element={<h2>HOLA</h2>} />
        <Route path="applicantList/:id" element={<ApplicantList />} />
      </Route>

      {/* Private admin Routes */}
      <Route path="/admin/login" element={<Login />} />
      <Route path="/admin" element={<RouteAdmin />}>
        <Route path="" element={<AdminDashboard />} />
      </Route>
    </Routes>
  );
};
export default RouterComponent;
