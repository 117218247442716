import { Prima } from "components/Auctioners/AddPrima/AddPrima.types";

export const calculatePrimaForPrice = (primaObj?: Prima[], price?: number) => {
  if (!primaObj || typeof price !== "number") return 0.2;

  for (let i = 0; i < primaObj.length; i++) {
    const rango = primaObj[i];

    // Asegurarse de que rangoInferior y rangoSuperior no son undefined antes de usarlos
    const rangoInferior = rango.rangoInferior ?? -Infinity; // Valor por defecto si es undefined
    const rangoSuperior = rango.rangoSuperior ?? Infinity; // Valor por defecto si es undefined

    if (price >= rangoInferior && price <= rangoSuperior) {
      return rango.prima ?? 0.2;
    }
  }

  return primaObj?.[primaObj?.length - 1].prima ?? 0.2;
};
