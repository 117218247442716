// Interfaces and types from context Globals

import { Dispatch, SetStateAction } from "react";
import { WinnedAuction } from "services/Auctioners/Auctioners.service.types";
import { Item } from "services/Auctioners/Auctioners.service.types";
import { Notification } from "services/notifications/notifications.service.types";

// Provider Props
export interface GlobalsProviderProps {
  children: React.ReactNode;
}

// Provider value
export interface GlobalsProviderValue {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  openNotifications: boolean;
  setOpenNotifications: Dispatch<SetStateAction<boolean>>;
  currentNotification?: Notification;
  setCurrentNotification: Dispatch<SetStateAction<Notification | undefined>>;
  selectedIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  selectedSubIndex: number;
  setSelectedSubIndex: Dispatch<SetStateAction<number>>;
  currentItem?: Item;
  setCurrentItem: Dispatch<SetStateAction<Item | undefined>>;
  fromEdit: boolean;
  setFromEdit: Dispatch<SetStateAction<boolean>>;
  selectedClient?: number;
  setSelectedClient: Dispatch<SetStateAction<number | undefined>>;
  currentAuction?: WinnedAuction;
  setCurrentAuction: Dispatch<SetStateAction<WinnedAuction | undefined>>;
  numberOfViewers: number;
  setNumberOfViewers: Dispatch<SetStateAction<number>>;
}

// Objeto de valor predeterminado
export const defaultGlobalsProviderValue: GlobalsProviderValue = {
  isLoading: false,
  setIsLoading: () => {},
  openNotifications: false,
  setOpenNotifications: () => {},
  currentNotification: undefined,
  setCurrentNotification: () => {},
  selectedIndex: 0,
  setSelectedIndex: () => {},
  selectedSubIndex: 0,
  setSelectedSubIndex: () => {},
  currentItem: undefined,
  setCurrentItem: () => {},
  fromEdit: false,
  setFromEdit: () => {},
  selectedClient: undefined,
  setSelectedClient: () => {},
  currentAuction: undefined,
  setCurrentAuction: () => {},
  numberOfViewers: 0,
  setNumberOfViewers: () => {}
};
