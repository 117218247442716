import React from "react";

import Styles from "./ButtonLarge.styles";
import { ButtonLargeProps as Props } from "./ButtonLarge.types";
import Spinner from "components/Spinner/Spinner";

const ButtonLarge: React.FC<Props> = props => {
  const { type, onClick, variant, children } = props;
  const { id, className, disabled, size } = props;
  const { startIcon, loading } = props;

  return (
    <Styles
      size={size ?? "large"}
      variant={variant}
      type={type}
      onClick={onClick}
      id={id}
      className={className}
      disabled={disabled}
      startIcon={startIcon}
      loading={loading ? 1 : 0}
    >
      {loading ? <Spinner /> : children}
    </Styles>
  );
};

ButtonLarge.defaultProps = {};

export default ButtonLarge;
