import { Tab } from "@headlessui/react";
import { Link, Rating, TextField, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ItemCard from "components/Auctioners/itemCard/ItemCard";
import ChartReviews from "components/ChartReviews/ChartReviews";
import Button from "components/Globals/Button/Button";
import ReviewCard from "components/ReviewCard/ReviewCard";
import SellerPresentation from "components/SellerPresentation/SellerPresentation";
import EmptyState from "components/emptyState/EmptyState";
import { variables } from "config/variables";
import FlatList from "flatlist-react";
import { useAppSelector } from "hooks";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { addReviewService } from "services/Auctioners/Auctioners.service";
import { SellerReview } from "services/Auctioners/Auctioners.service.types";
import { fail, successful } from "utils/Swal";

import Styles, { style } from "./SellerProfile.styles";
import { SellerProfileProps as Props } from "./SellerProfile.types";

const { palette } = variables;

const SellerProfile: React.FC<Props> = props => {
  const { sellerProfile, items, reviews } = props;
  const { name_business, user_id: sellerId } = sellerProfile ?? {};

  const queryClient = useQueryClient();
  const { user } = useAppSelector(state => state.authReducer);

  const [titleComment, setTitleComment] = useState("");
  const [comment, setComment] = useState("");
  const [score, setScore] = useState(5);

  const handleSubmit = async () => {
    try {
      await addReviewService({
        seller_id: `${sellerId}`,
        title: titleComment,
        comment,
        score
      });
      successful("Reseña añadida con éxito");
      await queryClient.invalidateQueries("sellerReviews");
      await queryClient.refetchQueries("sellerReviews");
      await queryClient.invalidateQueries("seller");
      await queryClient.refetchQueries("seller");
    } catch (error) {
      fail("Hubo un error al realizar la reseña");
    }
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    const headerOffset = 240;
    const elementPosition = element?.getBoundingClientRect()?.top;
    const offsetPosition =
      (elementPosition ?? 0) + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  const renderBreadCrumb = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Perfil de vendedor
        </Link>
        <Typography color="text.primary">{name_business}</Typography>
      </Breadcrumbs>
    );
  };

  const renderTabs = () => {
    return (
      <Tab.Group>
        <Tab.List className="SellerProfile__container--tabs">
          <Tab
            className={({ selected }) =>
              selected ? "text-primary" : "text-black"
            }
            onFocus={() => scrollToSection("itemsVendor")}
          >
            Lotes del vendedor
            <hr className="w-[180px] h-[4px] primary" />
          </Tab>
          <Tab
            className={({ selected }) =>
              selected ? "text-primary" : "text-black"
            }
            onFocus={() => scrollToSection("reviewsSection")}
          >
            Reseñas
            <hr className="w-[180px] h-[4px] primary" />
          </Tab>
          {user ? (
            <Tab
              className={({ selected }) =>
                selected ? "text-primary" : "text-black"
              }
              onFocus={() => scrollToSection("createReview")}
            >
              Escribe tu reseña
              <hr className="w-[180px] h-[4px] primary" />
            </Tab>
          ) : null}
        </Tab.List>
      </Tab.Group>
    );
  };

  const renderItemContainer = () => {
    return (
      <section className="SellerProfile__items-content" id="itemsVendor">
        <h2 className="SellerProfile__title">Lotes del vendedor</h2>
        <div className={`SellerProfile__items-container`}>
          {items?.map((item, idx) => {
            if (idx > 9) return null;

            return (
              <ItemCard
                className="SellerProfile__items-card"
                item={item}
                auctionId={item?.auction_id}
                key={idx}
                miniver={false}
              />
            );
          })}
        </div>
      </section>
    );
  };

  const renderComments = () => {
    return (
      <section className="SellerProfile__comments" id="reviewsSection">
        <h2 className="SellerProfile__title">Reseñas y comentarios</h2>
        <FlatList
          list={reviews ?? []}
          renderItem={(review: SellerReview, key: string | number) => (
            <ReviewCard key={key} review={review} />
          )}
          renderWhenEmpty={() => <EmptyState />}
          sortBy={["updated_at"]}
          sortDescending
        />
      </section>
    );
  };

  const renderForm = () => {
    return (
      <div className="SellerProfile__form" id="createReview">
        <h2 className="SellerProfile__title">Escribe tu reseña</h2>
        <h3 className="SellerProfile__label">Puntuación</h3>
        <Rating
          name="review"
          style={{ color: palette.primary }}
          precision={0.5}
          size="large"
          value={score}
          onChange={(event, newValue) => {
            if (newValue) setScore(newValue);
          }}
        />
        <TextField
          label="Título"
          variant="outlined"
          placeholder="Título"
          type="text"
          sx={style}
          value={titleComment}
          onChange={e => setTitleComment(e.target.value)}
        />

        <TextField
          id="comment"
          label="Comentario"
          variant="outlined"
          placeholder="Comentario"
          type="text"
          sx={style}
          value={comment}
          multiline
          rows={3}
          onChange={e => setComment(e.target.value)}
        />
        <Button
          onClick={handleSubmit}
          variant="contained"
          className="SellerProfile__button"
          size="large"
        >
          Enviar reseña
        </Button>
      </div>
    );
  };

  return (
    <Styles className="SellerProfile">
      {renderBreadCrumb()}
      <div className="SellerProfile__container">
        <SellerPresentation sellerProfile={sellerProfile} />
        <ChartReviews sellerProfile={sellerProfile} />
        {renderTabs()}
        {renderItemContainer()}
        {renderComments()}
        {user ? renderForm() : null}
      </div>
    </Styles>
  );
};

SellerProfile.defaultProps = {};

export default SellerProfile;
