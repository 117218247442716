import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import KeyIcon from "@mui/icons-material/Key";
import LoginIcon from "@mui/icons-material/Login";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PaidIcon from "@mui/icons-material/Paid";
import PauseIcon from "@mui/icons-material/Pause";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VideocamIcon from "@mui/icons-material/Videocam";

import { handleGetAccessCode } from "components/Auctioners/auctionInfo/AuctionInfo.helpers";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import { CONSTANTS } from "config/constants";
import { useAppSelector } from "hooks";
import { updateCurrentPrice } from "redux/actions/auctionActions";
import { pauseLoteService } from "services/Auctioners/Auctioners.service";
import { finalizeProduct } from "services/Auctioners/Auctioners.service";
import { addTimeToItem } from "services/Auctioners/Auctioners.service";
import { useOfferAuction } from "services/Auctioners/Auctioners.service.mutation.hooks";
import { useGetAuctionCodeAccess } from "services/auctionAccess/auctionAccess.service.hooks";
import { pusher } from "services/pusher/pusherApi";
import { fail } from "utils/Swal";
import CustomButton from "../Globals/customButton/CustomButton";
import { getNextOffer, getTimeRemain } from "./AuctionButtons.helpers";
import { AuctionButtonsProps } from "./AuctionButtons.types";
import Styles from "./AuctionButtons.styles";

const { INITIAL_TIME, INTERVAL } = CONSTANTS;
const { NORMAL, SALA, TELEFONICA, BAJO_MESA, COMMENT_TYPE } = CONSTANTS;

function AuctionButtons(props: AuctionButtonsProps) {
  const { createAdvice, offerData, setModalLogin } = props ?? {};
  const { auctionId, auctionData, currentItem } = props ?? {};
  const auctionIdNumber = auctionId as number; // refactor for resolving type change issue.

  const { user_id: ownerId, country, access } = auctionData ?? {};
  const { streamer } = auctionData ?? {};
  const { timeZone } = country ?? {};
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const { user } = useAppSelector(state => state.authReducer);
  const { lastBid, lastComment } = useAppSelector(
    state => state.auctionsReducer
  );
  const { currentPrice = 0 } = useAppSelector(state => state.auctionsReducer);
  const { type } = lastComment ?? {};
  const channel = pusher.subscribe(`liveauction${auctionId ?? id}`);
  const [countdown, setCountdown] = useState(INITIAL_TIME);
  const { id: uid } = user ?? {};

  const queryClient = useQueryClient();
  const { ActualPrice = 0 } = lastBid ?? {};
  const isOwner = uid === ownerId;
  const { prices } = auctionData ?? {};
  const { state: auctionState } = auctionData ?? {};
  const { price_base, finalize_item } = currentItem ?? {};
  const { status: loteState, id: itemId = 0 } = currentItem ?? {};
  const [paused, setPaused] = useState(false);
  const [remainTime, setRemainTime] = useState<number>(INITIAL_TIME);
  const [hasBeenModified, setHasBeenModified] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const hasStarted = auctionState === 2;
  const { mutateAsync, reset: resetMutation, isLoading } = useOfferAuction();
  const { data: realAccessCode } = useGetAuctionCodeAccess(auctionIdNumber);

  const handleFinalize = useCallback(async () => {
    if (!isOwner || !itemId) {
      console.error("Error");
      return;
    }
    await finalizeProduct(itemId).catch(() =>
      console.error("Error al cerrar subasta")
    );
  }, [isOwner, itemId]);

  const deactivateBidButton =
    auctionState !== 1 ||
    loteState !== 2 ||
    lastBid?.user_id === user?.id ||
    type === COMMENT_TYPE.pause ||
    isLoading;
  console.log("auctionState:", auctionState);
  console.log("loteState:", loteState);
  console.log("lastBid?.user_id:", lastBid?.user_id);
  console.log("user?.id:", user?.id);
  console.log("deactivateBidButton:", deactivateBidButton);
  // console.log("auctionState");
  // console.log(auctionState);
  // console.log("loteState");
  // console.log(loteState);
  // console.log("lastBid?.user_id");
  // console.log(lastBid?.user_id);
  // console.log(user?.id);

  const startAuction = useCallback(() => {
    console.log("La subasta ha comenzado!");
  }, []);

  const nextOffer =
    price_base === currentPrice && offerData.length === 0
      ? +price_base
      : getNextOffer(+currentPrice, +ActualPrice, prices);

  const handlePlayPause = useCallback(async () => {
    if (!paused) {
      createAdvice(`La subasta ha sido puesta en pausa`, COMMENT_TYPE.pause);
      setPaused(!paused);
      setHasBeenModified(true);
      await pauseLoteService(itemId);
    } else {
      createAdvice(`Comienza nuevamente la subasta `, COMMENT_TYPE.close);
      setPaused(!paused);
      await pauseLoteService(itemId);
    }
  }, [createAdvice, itemId, paused]);

  const restartTimer = useCallback(() => {
    setRemainTime(INITIAL_TIME);
    setHasBeenModified(true);
  }, []);

  const handleAddSeconds = useCallback(async () => {
    try {
      await addTimeToItem(auctionIdNumber, itemId);
      restartTimer();
    } catch (error) {
      fail("Error al intentar reiniciar el contador");
    }
  }, [auctionIdNumber, itemId, restartTimer]);

  const getTextToRender = (
    type: number,
    userId: number,
    currentPrice: number
  ) => {
    switch (type) {
      case NORMAL:
        return `$${currentPrice}: Oferta online Oferente ${userId}`;
      case TELEFONICA:
        return `$${currentPrice}: Oferta telefónica`;
      case SALA:
        return `$${currentPrice}: Oferta en sala`;
      case BAJO_MESA:
        return `$${currentPrice}: Oferta bajo sobre Oferente ${userId}`;
      default:
        return `$${currentPrice}: Oferta online Oferente ${userId}`;
    }
  };

  const submitHandler = useCallback(
    async (auctionType: number) => {
      try {
        const newOffer =
          price_base === currentPrice && offerData.length === 0
            ? +price_base
            : getNextOffer(+currentPrice, +ActualPrice, prices);
        await mutateAsync({
          item_id: +itemId,
          auction_id: +auctionId,
          offert: newOffer,
          type: auctionType
        })
          .then(() => {
            const adviceText = getTextToRender(auctionType, uid, newOffer);
            createAdvice(adviceText, COMMENT_TYPE.simpleChat);
          })
          .catch(() => {});
        resetMutation();
      } catch (error) {
        queryClient.invalidateQueries("offer");
        fail("Ocurrió un error al realizar la puja");
      }
    },
    [
      ActualPrice,
      auctionId,
      createAdvice,
      currentPrice,
      itemId,
      mutateAsync,
      offerData.length,
      price_base,
      prices,
      queryClient,
      resetMutation,
      uid
    ]
  );

  channel?.bind("App\\Events\\BidEvent", () => {
    restartTimer();
  });

  useEffect(() => {
    return () => {
      dispatch(updateCurrentPrice("00"));
    };
  }, [dispatch]);

  const handleDirectNavigate = async (id?: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(
      `/live-auction/stream/${id}`,
      "_blank",
      "resizable=no,scrollbars=no,location=no, menubar=no,width=880,height=720,popup"
    );
  };

  const renderActionButtons = () => {
    if (!isOwner) return;

    const styleClass = () =>
      "AuctionButtons__component--icon AuctionButtons__component--icon-secondary";

    const renderText = `${
      Number.isFinite(remainTime) ? remainTime / 1000 : "-"
    } segundos`;

    return (
      <div className="AuctionButtons__buttonsContainer">
        <div className="AuctionButtons__component--timer">
          <AccessTimeFilledIcon className={styleClass()} />
          {hasStarted && (
            <h4 className="AuctionButtons__component--timer-text">
              {renderText}
            </h4>
          )}
        </div>
        <div className="AuctionButtons__container--left">
          <CustomButton
            color={"primary"}
            text={paused ? "Play" : "Pause"}
            onClick={handlePlayPause}
            disabled={!hasStarted}
            icon={
              !paused ? (
                <PauseIcon className={styleClass()} />
              ) : (
                <PlayArrowIcon className={styleClass()} />
              )
            }
            className="AuctionButtons__adminButton"
          />
          <CustomButton
            color={"secondary"}
            text={"Reiniciar tiempo"}
            disabled={!hasStarted}
            onClick={() => handleAddSeconds()}
            icon={<AccessTimeFilledIcon className={styleClass()} />}
            className="AuctionButtons__adminButton"
          />

          <CustomButton
            color={"secondary"}
            text={"Finalizar"}
            onClick={handleFinalize}
            disabled={!hasStarted}
            icon={<CheckCircleOutlineIcon className={styleClass()} />}
            className="AuctionButtons__adminButton"
          />
          <CustomButton
            color={"secondary"}
            text={"Sala"}
            onClick={() => submitHandler(SALA)}
            icon={<MapsHomeWorkIcon className={styleClass()} />}
            disabled={type === COMMENT_TYPE.pause || !hasStarted}
            className="AuctionButtons__adminButton"
          />
          <CustomButton
            color={"secondary"}
            text={"Teléfono"}
            onClick={() => submitHandler(TELEFONICA)}
            icon={<PermPhoneMsgIcon className={styleClass()} />}
            disabled={type === COMMENT_TYPE.pause || !hasStarted}
            className="AuctionButtons__adminButton"
          />
          {streamer && (
            <CustomButton
              color={"secondary"}
              text={"Stream"}
              onClick={() => handleDirectNavigate(auctionIdNumber)}
              icon={<VideocamIcon className={styleClass()} />}
              className="AuctionButtons__adminButton"
            />
          )}
        </div>
      </div>
    );
  };

  const styleClass = () =>
    !isOwner ? "AuctionButtons__container--global-noOwner" : "";

  const styleClassContainer = () =>
    `AuctionButtons__container--right-container ${
      !isOwner ? "AuctionButtons__container--right-noOwner" : ""
    }`;

  const renderButtonsHandler = () => {
    if (!uid || uid === undefined) {
      return (
        <ButtonLarge
          variant="contained"
          startIcon={
            <LoginIcon className="AuctionButtons__component--button-icon" />
          }
          className="AuctionButtons__component--button AuctionButtons__component--button-success"
          loading={isLoading}
          onClick={() => setModalLogin(true)}
        >
          Iniciar sesión
        </ButtonLarge>
      );
    }

    if (uid && access === 4 && !hasAccess) {
      return (
        <ButtonLarge
          variant="contained"
          startIcon={
            <KeyIcon className="AuctionButtons__component--button-icon" />
          }
          className="AuctionButtons__component--button AuctionButtons__component--button-success"
          loading={isLoading}
          onClick={async () => {
            await handleGetAccessCode(
              setHasAccess,
              realAccessCode,
              auctionIdNumber
            );
          }}
          id="IdCode"
        >
          Ingresar código de subasta
        </ButtonLarge>
      );
    }

    if (!(lastBid?.user_id === user?.id)) {
      return (
        <ButtonLarge
          variant="contained"
          startIcon={
            <PaidIcon className="AuctionButtons__component--button-icon" />
          }
          className="AuctionButtons__component--button"
          disabled={deactivateBidButton}
          loading={isLoading}
          onClick={() => submitHandler(NORMAL)}
        >
          Realizar puja
        </ButtonLarge>
      );
    }

    return (
      <ButtonLarge
        variant="contained"
        startIcon={
          <EmojiEventsIcon className="AuctionButtons__component--button-icon" />
        }
        className="AuctionButtons__component--button AuctionButtons__component--button-success"
        disabled={deactivateBidButton}
        loading={isLoading}
      >
        Estas ganando
      </ButtonLarge>
    );
  };

  useEffect(() => {
    setHasBeenModified(false);
    setRemainTime(INITIAL_TIME);
  }, [itemId, restartTimer]);

  useEffect(() => {
    if (loteState === 3) setPaused(true);
  }, [loteState]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (paused) return;
      setRemainTime(prev => {
        if (prev >= INTERVAL && hasBeenModified) {
          return prev - INTERVAL;
        } else return getTimeRemain(finalize_item, remainTime, timeZone);
      });
    }, INTERVAL);

    return () => clearInterval(interval);
  }, [
    finalize_item,
    currentItem,
    remainTime,
    itemId,
    timeZone,
    paused,
    hasBeenModified
  ]);
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown > 0) {
          console.log(`Contador: ${prevCountdown - 1}`);
          return prevCountdown - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      console.log("El contador llegó a cero, iniciando la subasta...");
      startAuction();
    }
  }, [countdown, startAuction]);

  return (
    <Styles className="AuctionButtons">
      <div className={`AuctionButtons__container--global ${styleClass()}`}>
        {renderActionButtons()}
        <div className="AuctionButtons__container--right">
          <div className={styleClassContainer()}>
            <div className="AuctionButtons__container--price">
              <span className="AuctionButtons__component--span">U</span>
              <span className="AuctionButtons__component--span">S</span>
              <span className="AuctionButtons__component--span">D</span>
              <span className="AuctionButtons__component--span">$</span>
              {nextOffer
                .toString()
                ?.split("")
                ?.map((char, idx) => (
                  <span
                    className="AuctionButtons__component--span"
                    key={`ch${idx}`}
                  >
                    {char}
                  </span>
                ))}
            </div>
            <div className="AuctionButtons__container--actions">
              {renderButtonsHandler()}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

export default AuctionButtons;
